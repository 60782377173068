import { Grid, IconButton, TextField, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { OPERATORS } from './companies-constants'
import { NewFilterOptions } from './companies-types'
import CloseIcon from '@mui/icons-material/Close'
import { Column } from './companies-table-definitions'

export interface CompaniesNewFilterProps {
  newFilter: NewFilterOptions | undefined
  hideLogicalOps: boolean
  columns: readonly Column[]
  removeNewFilter: () => void
  handleNewColumnChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleNewOpChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const CompaniesNewFilter = (props: CompaniesNewFilterProps) => {
  const { newFilter, removeNewFilter, hideLogicalOps, columns, handleNewColumnChange, handleNewOpChange } = props

  if (!newFilter) return null

  return (
    <Grid container item spacing={1}>
      <Grid container item xs={1}>
        <IconButton onClick={removeNewFilter}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid container item xs={1} alignItems="center">
        <Typography id="logical-operator" sx={{ visibility: hideLogicalOps ? 'hidden' : 'visible' }}>
          And
        </Typography>
      </Grid>
      <Grid container item xs={3}>
        <TextField
          sx={{ minWidth: '100%' }}
          id="column"
          name="column"
          value={columns.find((col) => col.id === newFilter.columnName)?.id ?? ''}
          label="Column"
          onChange={handleNewColumnChange}
          select
          size="small"
        >
          {columns.map((col) => {
            return (
              <MenuItem value={col.id} key={col.id}>
                {col.label}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid container item xs={3}>
        <TextField
          sx={{ minWidth: '100%' }}
          id="operator"
          name="operator"
          value={newFilter?.filterType?.operator ?? ''}
          disabled={!newFilter?.filterType?.columnType}
          label="Operator"
          select
          size="small"
          onChange={handleNewOpChange}
        >
          {OPERATORS[newFilter?.filterType?.columnType ?? 'string'].map((op) => {
            return (
              <MenuItem value={op.value} key={op.value}>
                {op.title}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid container item xs={4}></Grid>
    </Grid>
  )
}

export default CompaniesNewFilter
