export const NUMBER_OPS = [{ title: 'equals', value: 'eq' }, { title: 'is greater than or equal to', value: 'gte' }, { title: 'is less than or equal to', value: 'lte' }, { title: 'is greater than', value: 'gt' }, { title: 'is less than', value: 'lt' }]
export const DATE_OPS = [{ title: 'is on or before', value: 'iob' }, { title: 'is on or after', value: 'ioa' }, { title: 'is before', value: 'ib' }, { title: 'is after', value: 'ia' }, { title: 'is on', value: 'io' }]
export const STRING_OPS = [{ title: 'equals', value: 'eq' }, { title: 'contains', value: 'contains' }]

export const OPERATORS = {
    string: STRING_OPS,
    number: NUMBER_OPS,
    date: DATE_OPS,
}

export const TYPE_MAPPINGS: Record<string, string> = {
    companyName: "string",
    coachName: "string",
    companyTier: "number",
}