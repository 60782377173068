import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthenticationContext } from '../contexts/authentication-context'
import jwt_decode from 'jwt-decode'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import ErrorIcon from '@mui/icons-material/Error'
import { LoadingContext, LoadingState } from '../contexts/loading-context'
import { useNavigate } from 'react-router-dom'
import { ChimeContext } from '../contexts/chime-context'
import AppIcon from './shared/icons/app-icon'
import { format } from 'date-fns'

const Login = () => {
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState<boolean>()
  const [errorText, setErrorText] = useState<string>()
  const { setAuthenticationState } = useContext(AuthenticationContext)
  const { loading, setLoading } = useContext(LoadingContext)
  const [userLacksPermission, setUserLacksPermission] = useState(false)
  const { resetChime } = useContext(ChimeContext)
  const navigate = useNavigate()
  const accessRoles = ['devadmin', 'coach', 'customer_success']
  const getJwt = (): string => {
    const jwt = document.cookie
      .split('; ')
      .filter((row) => row.startsWith('jwt='))
      .map((c) => c.split('=')[1])[0]
    return jwt
  }

  useEffect(() => {
    const jwt = getJwt()
    const claims: any = jwt ? jwt_decode(jwt) : null
    if (
      !jwt ||
      new Date(parseInt(claims.exp) * 1000) <= new Date() ||
      !claims?.roles ||
      !accessRoles.some((role) => claims.roles.includes(role))
    ) {
      //Token expired or didn't exist yet, user must login.
      console.log(`useEffect: [[${LoadingState[loading]} --> Ready]], removing authentication state`)
      setAuthenticationState({
        userInfo: undefined,
        isAuthenticated: false,
        jwt: undefined,
      })
      setLoading(LoadingState.Unauthorized)
    } else if (loading === LoadingState.Loading) {
      console.log(
        `useEffect: [[${LoadingState[loading]} (Loading?) --> LoadingChatCredentials]], setting authentication state`,
      )
      setAuthenticationState({
        userInfo: {
          username: claims.nickname,
          firstname: claims.given_name,
          lastname: claims.family_name,
          email: claims.email,
          admin: claims.roles?.includes('devadmin'),
          userId: claims.user_id,
          roles: claims.roles,
        },
        isAuthenticated: true,
        jwt: jwt,
      })
      setLoading(LoadingState.LoadingChatCredentials)
    }
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setErrorText('')
    setError(false)
    console.log(`handleSubmit: [[${LoadingState[loading]} --> Authenticating]]`)
    setLoading(LoadingState.Authenticating)

    const payload = JSON.stringify(Object.fromEntries(new FormData(event.currentTarget)))
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          console.log('handleSubmit:  Just set cookies on login.')
          return response
        } else if (response.status === 401) {
          throw Error('Incorrect password and/or username.')
        } else {
          throw Error('An unknown authentication occurred.')
        }
      })
      .then(() => {
        const jwt = getJwt()
        const claims: any = jwt ? jwt_decode(jwt) : null
        console.log('JWT: ', jwt)
        if (!claims?.roles || !accessRoles.some((role) => claims.roles.includes(role))) {
          console.log('no proper role access for portal')
          setLoading(LoadingState.SetDefaultParams)
          setUserLacksPermission(true)
          return
          // throw Error('User lacks required role to access this application')
        }
        console.log(
          `handleSubmit: [[${LoadingState[loading]} --> LoadingChatCredentials]], setting authentication state`,
        )

        setAuthenticationState({
          userInfo: {
            username: claims.nickname,
            firstname: claims.given_name,
            lastname: claims.family_name,
            email: claims.email,
            admin: claims.roles?.includes('devadmin'),
            userId: claims.user_id,
            roles: claims.roles,
          },
          isAuthenticated: true,
          jwt: jwt,
        })

        setLoading(LoadingState.LoadingChatCredentials)

        // Set the timer to navigate the user to '/login' when the JWT expires
        const jwtExpiresAt = parseInt(claims.exp) * 1000
        const currentTime = new Date().getTime()
        const expiresIn = jwtExpiresAt - currentTime

        const hours = Math.floor(expiresIn / 3600000)
        const minutes = Math.floor((expiresIn % 3600000) / 60000)
        const seconds = Math.floor((expiresIn % 60000) / 1000)

        console.log(`Setting timer to navigate user to /login page on jwt timeout
                    jwtExpiresAt: ${new Date(jwtExpiresAt).toLocaleString()}
                    currentTime: ${new Date(currentTime).toLocaleString()}
                    expiresIn: ${hours}h ${minutes}m ${seconds}s`)

        setTimeout(() => {
          console.log('Jwt timed out, logging out & re-routing to login page')
          resetChime()
          setAuthenticationState({
            userInfo: undefined,
            isAuthenticated: false,
            jwt: undefined,
          })
          setLoading(LoadingState.Unauthorized)
          navigate('/login', { state: { prevPath: location.pathname, prevSearch: location.search } })
        }, expiresIn)
      })
      .catch((err) => {
        setError(true)
        setErrorText(err.message)
        setAuthenticationState({
          userInfo: undefined,
          isAuthenticated: false,
          jwt: undefined,
        })
        setLoading(LoadingState.Unauthorized)
      })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  if (loading === LoadingState.Authenticating)
    return (
      <Box sx={{ marginLeft: '50vw', marginTop: '50vh' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <Paper
        elevation={2}
        sx={{
          padding: '0px 0px 0px 0px',
          width: '430px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: '430px',
            backgroundColor: '#1976d2',
            height: '4.5vh',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        ></Box>
        <Box mt={1} mb={1} pt={4} pb={2} display="flex" sx={{ maxHeight: '133px', justifyContent: 'center' }}>
          <AppIcon />
        </Box>
        {userLacksPermission ? (
          <>
            <Box pl={5} pr={5} pb={4} pt={10}>
              <Typography fontSize="14px">Sorry, you don’t have access to this application.</Typography>
              <Box pb={2}></Box>
              <Typography fontSize="14px">If you meant to go to bNOTES, click the button below.</Typography>
            </Box>
            <Box pl={6} pb={2} sx={{ marginRight: 'auto' }}>
              <Button href="https://bnotes2.bardavon.com/login" fullWidth variant="contained">
                GO TO BNOTES
              </Button>
            </Box>
            <Box mt={'98px'}></Box>
          </>
        ) : (
          <>
            <Box pl={3} pr={3} sx={{ width: '100%' }}>
              {error ? (
                <Alert
                  iconMapping={{
                    error: <ErrorIcon sx={{ fill: '#FFFFFF' }} />,
                  }}
                  severity="error"
                  sx={{
                    backgroundColor: '#B71C1C',
                    color: 'white',
                    overflow: 'hidden',
                    boxShadow:
                      '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)',
                    mt: 2,
                    mb: 1,
                    py: 0,
                  }}
                >
                  <AlertTitle>{errorText}</AlertTitle>
                </Alert>
              ) : null}
            </Box>
            <Box pl={5} pr={5} pb={12} component="form" autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                error={error}
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                size="small"
                onChange={(event) => setUsername(event.target.value)}
              />
              <TextField
                error={error}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={password}
                size="small"
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!username || !password}
              >
                Log in
              </Button>
              <Box display={'flex'} justifyContent={'center'}>
                <Typography variant="caption">
                  Forgot{' '}
                  <Link
                    href={`${process.env.REACT_APP_BNOTES_BASE_URL}/forgot/username`}
                    target="_blank"
                    sx={{ textDecoration: 'none' }}
                  >
                    username
                  </Link>{' '}
                  or{' '}
                  <Link
                    href={`${process.env.REACT_APP_BNOTES_BASE_URL}/forgot/password`}
                    target="_blank"
                    sx={{ textDecoration: 'none' }}
                  >
                    password?
                  </Link>
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Paper>
    </Container>
  )
}

export default Login
