import { Box } from '@mui/material'
import { ReactComponent as AddNote } from '../../../images/icons/assignment_add.svg'
import React from 'react'

const AddNoteIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#45BEA9',
        width: '67px',
        height: '69px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        marginLeft: '1rem',
        position: 'absolute',
        marginTop: '-1rem',
      }}
    >
      <AddNote />
    </Box>
  )
}

export default AddNoteIcon
