import { CoachTaskActivity } from "../worker-details/worker-details-types"
import { allCoachActivities, coachStatuses } from "../worker-details/worker-plan/coach-tasks-definitions"

export const LOGICAL_OPS = [{ title: 'And', value: "and" }, { title: 'Or', value: 'or' }]
export const NUMBER_OPS = [{ title: 'equals', value: 'eq' }, { title: 'is greater than or equal to', value: 'gte' }, { title: 'is less than or equal to', value: 'lte' }, { title: 'is greater than', value: 'gt' }, { title: 'is less than', value: 'lt' }]
export const DATE_OPS = [{ title: 'is on or before', value: 'iob' }, { title: 'is on or after', value: 'ioa' }, { title: 'is before', value: 'ib' }, { title: 'is after', value: 'ia' }, { title: 'is on', value: 'io' }]
export const STRING_OPS = [{ title: 'equals', value: 'eq' }, { title: 'contains', value: 'contains' }]
export const MULTI_OPS = [{ title: 'is in', value: 'in' }]

export const OPERATORS: Record<string, { title: string, value: string }[]> = {
    string: STRING_OPS,
    number: NUMBER_OPS,
    date: DATE_OPS,
    multi: MULTI_OPS,
}

export const RISK_LEVELS: string[] = ["Very Low", "Low", "Moderate", "High", "Very High"]
export const ACTIVITIES: CoachTaskActivity[] = allCoachActivities as CoachTaskActivity[]
export const STATUSES: string[] = coachStatuses.map((status) => status.label)

export const TYPE_MAPPINGS: Record<string, string> = {
    userid: "string",
    risklevel: "multi",
    trend: "number",
    name: "string",
    engagement: "string",
    coachtask: "multi",
    taskstatus: "multi",
    duedate: "date",
    company: "string",
    tier: "number",
    coach: "string",
}