import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif',
        ].join(','),
    },
    palette: {
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    padding: '8px 16px 8px 16px',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    '&::before': {
                        top: '25%',
                        transform: 'translateY(25%)',
                    },
                    '&::after': {
                        top: '25%',
                        transform: 'translateY(25%)',
                    },
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
        },
    },
})