import { Box, Divider, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { MotionCoachSet } from '../../workers/workers-types'
import ScoreLinearProgress from './score-linear-progress'

export interface MCSetViewData {
  mcs: MotionCoachSet
}

const MCSetView = (props: MCSetViewData) => {
  const { mcs } = props

  const handleClick = () => {
    console.log('handle click')
  }

  const set_start_date = new Date(mcs.setStartDate)
  const set_end_date = new Date(mcs.setEndDate)

  const overall_average = Math.round(mcs.overallScore * 10) / 10
  const arm = Math.round(mcs.averageArmScore * 10) / 10
  const back = Math.round(mcs.averageBackScore * 10) / 10
  const leg = Math.round(mcs.averageLegScore * 10) / 10
  const high_load = Math.round(
    (mcs.averageArmHighLoadPerHour + mcs.averageBackHighLoadPerHour + mcs.averageLegHighLoadPerHour) / 3.0,
  )
  const total_load = Math.round(
    (mcs.averageArmLoadPerHour + mcs.averageBackLoadPerHour + mcs.averageLegLoadPerHour) / 3.0,
  )

  return (
    <Box sx={{ paddingLeft: '15px', paddingRight: '5px', paddingBottom: '10px' }}>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <Grid container direction="row" spacing={0}>
            <Grid item>
              <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#0B78D0' }}>
                {`${format(set_start_date, 'MM/d/yy')} - ${format(set_end_date, 'MM/d/yy')}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: '12px', fontWeight: 300, paddingLeft: '5px' }}>
                {mcs.sessionsRecorded} {mcs.sessionsRecorded === 1 ? 'session' : 'sessions'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {mcs.sessionsRecorded > 1 ? (
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <Typography sx={{ fontSize: '12px', fontWeight: '300', paddingTop: '5px' }}>
                Overall/Average score
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={0}>
                <Grid item>
                  <Box sx={{ width: '220px', mr: 1, paddingTop: '5px' }}>
                    <ScoreLinearProgress value={overall_average} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography sx={{ width: '40px', fontSize: '12px', fontWeight: 300 }}>{overall_average}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ width: '100%', mr: 2, paddingTop: '5px' }} item>
              <Grid container direction="row" spacing={0}>
                <Grid item>
                  <Typography sx={{ width: '40px', fontSize: '12px', fontWeight: 300 }}>Arm</Typography>
                </Grid>
                <Grid item>
                  <Box sx={{ width: '160px', mr: 1.5, paddingTop: '5px' }}>
                    <ScoreLinearProgress value={arm} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography sx={{ width: '40px', fontSize: '12px', fontWeight: 300 }}>{arm}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={0}>
                <Grid item>
                  <Typography sx={{ width: '40px', fontSize: '12px', fontWeight: 300 }}>Back</Typography>
                </Grid>
                <Grid item>
                  <Box sx={{ width: '160px', mr: 1.5, paddingTop: '5px' }}>
                    <ScoreLinearProgress value={back} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography sx={{ width: '40px', fontSize: '12px', fontWeight: 300 }}>{back}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={0}>
                <Grid item>
                  <Typography sx={{ width: '40px', fontSize: '12px', fontWeight: 300 }}>Leg</Typography>
                </Grid>
                <Grid item>
                  <Box sx={{ width: '160px', mr: 1.5, paddingTop: '5px' }}>
                    <ScoreLinearProgress value={leg} />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography sx={{ width: '40px', fontSize: '12px', fontWeight: 300 }}>{leg}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={0}>
                <Grid item>
                  <Typography sx={{ width: '200px', fontSize: '12px', fontWeight: 500, paddingTop: '5px' }}>
                    High load movements (avg/hr)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ fontSize: '12px', fontWeight: 300, paddingLeft: '5px' }}>{high_load}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={0}>
                <Grid item>
                  <Typography sx={{ width: '200px', fontSize: '12px', fontWeight: 300 }}>
                    Total load (avg/hr)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ fontSize: '12px', fontWeight: 300, paddingLeft: '5px' }}>{total_load}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Typography sx={{ fontSize: '12px', fontWeight: 500, paddingTop: '5px', color: '#D32F2F' }}>
              Not enough data.
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Divider sx={{ paddingTop: '5px', paddingBottom: '5px' }} />
        </Grid>
      </Grid>
    </Box>
  )
}
export default MCSetView
