import React from 'react'
import { Typography, Box, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

interface InternalServerProps {
  loginRedirect: boolean
}
const InternalServer = (props: InternalServerProps) => {
  const { loginRedirect } = props
  const location = useLocation()
  const navigate = useNavigate()
  const onLoginClick = () => {
    navigate('/login', { state: { prevPath: location.pathname, prevSearch: location.search } })
  }

  return (
    <Box>
      <Typography>Internal Server error</Typography>
      {loginRedirect ? <Button onClick={onLoginClick}>Login</Button> : null}
    </Box>
  )
}

export default InternalServer
