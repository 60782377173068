import { Grid, Typography } from '@mui/material'
import InfoCard from '../shared/info-card'
import AddNoteIcon from '../shared/icons/add-note-icon'
import QueryStatsIcon from '../shared/icons/query-stats-icon'
import CheckCircleIcon from '../shared/icons/check-circle-icon'
import SMSIcon from '../shared/icons/sms-icon'
import { format } from 'date-fns'
import {
  CreatePlan,
  NotStartedStatus,
  PreventureReviewMCData,
  allCoachActivities,
} from '../worker-details/worker-plan/coach-tasks-definitions'
import { RouteContext } from '../../contexts/route-context'
import { useContext } from 'react'
import { AuthenticationContext } from '../../contexts/authentication-context'

export interface WorkersHeaderProps {
  plansDueCount: Number
  motionCoachSessionCount: Number
  coachTasksDue: Number
  coachTasksOverdue: Number
}

const WorkersHeader = (props: WorkersHeaderProps) => {
  const { plansDueCount, motionCoachSessionCount, coachTasksDue, coachTasksOverdue } = props
  const { userInfo } = useContext(AuthenticationContext)
  const { defaultRoute } = useContext(RouteContext)

  const defaultFilter =
    userInfo && !userInfo.admin && userInfo.roles.some((role) => role === 'coach')
      ? `riskLevel~in~Moderate, High, Very High~plus~coach~eq~${userInfo?.firstname} ${userInfo?.lastname}`
      : ''

  const overdueTasks = `${defaultRoute}?filter=${encodeURIComponent(
    `${
      defaultFilter
        ? `${defaultFilter}~plus~dueDate~iob~${format(new Date(), 'M/dd/yy')}`
        : `dueDate~iob~${format(new Date(), 'M/dd/yy')}`
    }`,
  )}`

  const reviewMCSets = `${defaultRoute}?filter=${encodeURIComponent(
    `${defaultFilter ? `${defaultFilter}~plus~` : ''}coachTask~in~${
      allCoachActivities.find((activity) => activity.label === CreatePlan)?.shortLabel
    },${allCoachActivities.find((activity) => activity.label === PreventureReviewMCData)?.shortLabel}`,
  )}`

  const plansDue = `${defaultRoute}?filter=${encodeURIComponent(
    `coachTask~in~${
      allCoachActivities.find((activity) => activity.label === CreatePlan)?.shortLabel
    }~plus~taskStatus~in~${NotStartedStatus}${
      userInfo?.roles.some((role) => role === 'coach')
        ? `~plus~coach~eq~${userInfo?.firstname} ${userInfo?.lastname}`
        : ''
    }`,
  )}`

  return (
    <Grid container>
      <Grid container item spacing={3}>
        <Grid item xs={3}>
          <InfoCard
            title="Plans due"
            value={plansDueCount?.toString() || '0'}
            valueColor="#45BEA9"
            link={plansDue}
            linkText="View in list"
            icon={<AddNoteIcon />}
            disableLink={!plansDueCount}
          />
        </Grid>
        <Grid item xs={3}>
          <InfoCard
            title="Movement Coach sets to review"
            value={motionCoachSessionCount?.toString() || '0'}
            valueColor="#8E24AA"
            link={reviewMCSets}
            linkText="View in list"
            icon={<QueryStatsIcon />}
            disableLink={!motionCoachSessionCount}
          />
        </Grid>
        <Grid item xs={3}>
          <InfoCard
            title="Tasks"
            value={`${coachTasksDue?.toString() || '0'} due today`}
            valueColor="#1D738F"
            link={overdueTasks}
            linkText="View in list"
            additionalInfo={
              <Typography fontSize="14px">
                <b>{coachTasksOverdue || 0}</b> overdue
              </Typography>
            }
            icon={<CheckCircleIcon />}
            disableLink={!coachTasksDue && !coachTasksOverdue}
          />
        </Grid>
        <Grid item xs={3} sx={{ visibility: 'hidden' }}>
          <InfoCard
            title="Messages needing reply"
            value="0"
            valueColor="#3425A0"
            link="/"
            linkText="View all"
            icon={<SMSIcon />}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WorkersHeader
