import { ReactNode, useMemo, useState } from 'react'
import { ILoadingContext, LoadingContext, LoadingState } from './loading-context'

interface LoadingProviderProps {
  children: ReactNode
}

const LoadingContextProvider = (props: LoadingProviderProps) => {
  const [loading, setLoading] = useState<LoadingState>(LoadingState.Loading)

  const { children } = props

  const updateLoading = (newLoadingState: LoadingState) => {
    console.log(
      `LoadingContextProvider.updateLoading: [[${LoadingState[loading]} -->` +
      ` ${LoadingState[newLoadingState]}]]`
    )
    setLoading(newLoadingState)
  }

  const values: ILoadingContext = useMemo(() => {
    return {
      loading,
      setLoading: updateLoading,
    }
  }, [loading])

  return <LoadingContext.Provider value={values}>{children}</LoadingContext.Provider>
}

export default LoadingContextProvider
