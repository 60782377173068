import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  const onHomeClick = () => {
    navigate('/workers')
  }

  return (
    <Box>
      <Typography>Not Found</Typography>
      <Button onClick={onHomeClick}>Home</Button>
    </Box>
  )
}

export default NotFound
