import { CoachTaskColumn, CoachTaskActivity, CoachTaskStatus } from "../worker-details-types"

export const columns: readonly CoachTaskColumn[] = [
    { id: 'activity', label: 'Coach Task', size: 5 },
    { id: 'dueDate', label: 'Due Date', size: 3 },
    { id: 'status', label: 'Status', size: 3 },
]


export const coachActivities: readonly CoachTaskActivity[] = [
    { id: 'peerwell_assign_program', label: 'PeerWell: Assign program' },
    { id: 'peerwell_follow_up', label: 'PeerWell: Follow up' },
    { id: 'preventure_assign_training', label: 'Preventure: Assign/create training' },
    { id: 'preventure_training_follow_up', label: 'Preventure: Training follow up' },
    { id: 'status_check', label: 'Status check w/ safety officer' },
    { id: 'general_update', label: 'General update' },
    { id: 'sensor_reminder', label: 'Sensor reminder' },
]

export const allCoachActivities: readonly CoachTaskActivity[] =
    [
        { id: 'create_plan', label: 'Create Plan', shortLabel: 'Create plan' },
        { id: 'preventure_review_mc_data', label: 'Preventure: Review MC data', shortLabel: 'Review data' },
        { id: 'peerwell_assign_program', label: 'PeerWell: Assign program', shortLabel: 'Assign program' },
        { id: 'peerwell_follow_up', label: 'PeerWell: Follow up', shortLabel: 'Follow up' },
        { id: 'preventure_assign_training', label: 'Preventure: Assign/create training', shortLabel: 'Assign training' },
        { id: 'preventure_training_follow_up', label: 'Preventure: Training follow up', shortLabel: 'Follow up' },
        { id: 'status_check', label: 'Status check w/ safety officer', shortLabel: 'Status check' },
        { id: 'general_update', label: 'General update', shortLabel: 'General' },
        { id: 'sensor_reminder', label: 'Sensor reminder', shortLabel: 'Sensor reminder' },
    ]

export const coachStatuses: readonly CoachTaskStatus[] = [
    { id: 'not_started', label: "Not started", extraProps: { sx: { backgroundColor: '#FFFFFF', border: '1px solid #979797' } } },
    { id: 'in_progress', label: "In progress", extraProps: { sx: { backgroundColor: '#A5D6A7', color: 'rgba(0,0,0,0.87)' } } },
    { id: 'stalled', label: 'Stalled', extraProps: { sx: { backgroundColor: '#FDD835' } } },
    { id: 'ignored', label: 'Ignored', extraProps: { sx: { backgroundColor: '#EDEDED' } } },
    { id: 'completed', label: 'Completed', extraProps: { sx: { backgroundColor: '#4CAF50', color: "#FFFFFF" } } },
]

export const CreatePlan = 'Create Plan'
export const NotStartedStatus = 'Not started'
export const Completed = 'Completed'
export const Ignored = 'Ignored'
export const PreventureReviewMCData = 'Preventure: Review MC data'