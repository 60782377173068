import React, { useContext } from 'react'
import { RouteObject, useRoutes } from 'react-router-dom'
import Admin from './components/admin/admin'
import Workers from './components/workers/workers'
import Companies from './components/companies/companies'
import Login from './components/login'
import Messages from './components/messages/messages'
import Overview from './components/overview/overview'
import CustomLayout from './components/shared/layout'
import NotFound from './errors/not-found'
import WorkerDetails from './components/worker-details/worker-details'
import CompanyDetails from './components/company-details/company-details'
import { RouteContext } from './contexts/route-context'

const RenderRoute = () => {
  const { defaultRoute } = useContext(RouteContext)
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <CustomLayout />,
      children: [
        { index: true, element: <Workers /> },
        { path: defaultRoute, element: <Workers /> },
        { path: '/companies', element: <Companies /> },
        { path: '/overview', element: <Overview /> },
        { path: '/messages', element: <Messages /> },
        { path: '/login', element: <Login /> },
        { path: '/admin', element: <Admin /> },
        { path: '/worker/:workerid', element: <WorkerDetails /> },
        { path: '/companies/:companyid', element: <CompanyDetails /> },
        { path: '*', element: <NotFound /> },
      ],
    },
  ]

  return useRoutes(routes)
}

export default RenderRoute
