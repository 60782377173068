import {
  Grid,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import { ACTIVITIES, OPERATORS, RISK_LEVELS, STATUSES, TYPE_MAPPINGS } from '../workers-constants'
import { Column } from './workers-table-definitions'
import CloseIcon from '@mui/icons-material/Close'
import { DateOps, FilterOptions, NumberOps, StringOps } from '../workers-types'
import { CoachTaskActivity } from '../../worker-details/worker-details-types'

export interface WorkersFilterProps {
  index: number
  column: string
  logicalOperator: string
  columns: readonly Column[]
  filterOptions: FilterOptions
  removeFilter: (column: string, index: number) => void
  updateFilter: (column: string, filterOption: FilterOptions, index: number) => void
}
const WorkersFilter = (props: WorkersFilterProps) => {
  const { index, column, columns, filterOptions, logicalOperator, removeFilter, updateFilter } = props

  const getMultiSelectValues = () => {
    switch (column) {
      case 'coachTask': {
        let activities: CoachTaskActivity[] = []
        ACTIVITIES.forEach((activity) => {
          if (!activities.some((act) => act.shortLabel === activity.shortLabel)) activities.push(activity)
        })
        return activities.map((activity, idx) => {
          return (
            <MenuItem value={activity.shortLabel as string} key={`${activity.id}-${idx}`}>
              {activity.shortLabel as string}
            </MenuItem>
          )
        })
      }
      case 'riskLevel':
        return RISK_LEVELS.map((riskLevel) => {
          return (
            <MenuItem value={riskLevel} key={riskLevel}>
              {riskLevel}
            </MenuItem>
          )
        })
      case 'taskStatus':
        return STATUSES.map((status) => {
          return (
            <MenuItem value={status} key={status}>
              {status}
            </MenuItem>
          )
        })
      default:
        return null
    }
  }

  const handleMultiSelectChange = (event: SelectChangeEvent<typeof filterOptions.multiValue>) => {
    const {
      target: { value },
    } = event
    const filter = { ...filterOptions }
    filter.multiValue = typeof value === 'string' ? value.split(',') : value
    updateFilter(column, filter, index)
  }

  return (
    <Grid container item spacing={1} key={`grid-${column}-${index}`}>
      <Grid container item xs={1}>
        <IconButton onClick={() => removeFilter(column, index)}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid container item xs={1} alignItems="center">
        <Typography id="logical-operator">{logicalOperator}</Typography>
      </Grid>
      <Grid container item xs={3}>
        <TextField
          id="column"
          value={column !== 'taskStatus' ? columns.find((col) => col.id === column)?.id : 'taskStatus'}
          label="Column"
          select
          size="small"
          sx={{ minWidth: '100%' }}
        >
          {[
            ...columns.map((col) => {
              return (
                <MenuItem value={col.id} key={col.id}>
                  {col.label}
                </MenuItem>
              )
            }),
            <MenuItem value={'taskStatus'} key={'taskStatus'}>
              Status
            </MenuItem>,
          ]}
        </TextField>
      </Grid>
      <Grid container item xs={3}>
        <TextField
          id="operation"
          value={filterOptions.filterType.operator}
          label="Operator"
          select
          size="small"
          sx={{ minWidth: '100%' }}
          onChange={(event) => {
            const filter = { ...filterOptions }
            switch (TYPE_MAPPINGS[column.toLowerCase()]) {
              case 'string':
                filter.filterType.operator = event.target.value as StringOps
                break
              case 'number':
                filter.filterType.operator = event.target.value as NumberOps
                break
              case 'date':
                filter.filterType.operator = event.target.value as DateOps
                break
              default:
                filter.filterType.operator = event.target.value as StringOps
            }
            updateFilter(column, filter, index)
          }}
        >
          {OPERATORS[filterOptions.filterType.columnType].map((op) => {
            return (
              <MenuItem value={op.value} key={op.value}>
                {op.title}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid container item xs={4}>
        {filterOptions.filterType.columnType !== 'multi' ? (
          <TextField
            value={filterOptions.value ?? ''}
            size="small"
            label="Value"
            sx={{ minWidth: '100%' }}
            onChange={(event) => {
              const filter = { ...filterOptions }
              filter.value = event.target.value ?? ''
              updateFilter(column, filter, index)
            }}
          />
        ) : (
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id={`multiselect-${column}`} size="small" htmlFor={column}>
              {column !== 'taskStatus' ? columns.find((col) => col.id === column)?.label : 'Status'}
            </InputLabel>
            <Select
              fullWidth
              multiple
              name={column}
              id={`multi-value-${column}`}
              value={filterOptions.multiValue.map((value) => value.trim())}
              input={
                <OutlinedInput
                  label={column !== 'taskStatus' ? columns.find((col) => col.id === column)?.label : 'Status'}
                />
              }
              label={columns.find((col) => col.id === column)?.label ?? ''}
              size="small"
              sx={{ minWidth: '100%' }}
              onChange={handleMultiSelectChange}
            >
              {getMultiSelectValues()}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  )
}

export default WorkersFilter
