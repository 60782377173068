import { Box, CircularProgress, Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import WorkersHeader from './workers-header'
import { WorkerData, CoachTaskData } from './workers-types'
import WorkersTable from './workers-table/workers-table'
import { AuthContext, AuthenticationContext } from '../../contexts/authentication-context'
import {
  allCoachActivities,
  Completed,
  CreatePlan,
  Ignored,
  NotStartedStatus,
  PreventureReviewMCData,
} from '../worker-details/worker-plan/coach-tasks-definitions'
import { compareAsc, format } from 'date-fns'
import { isOverdue, isToday } from '../worker-details/worker-plan/coach-utilities'

const Workers = () => {
  const { userInfo } = useContext<AuthContext>(AuthenticationContext)

  const [workers, setWorkers] = useState<Array<WorkerData>>()
  const [loading, setLoading] = useState<boolean>(true)
  const [plansDueCount, setPlansDueCount] = useState<Number>(0)
  const [motionCoachSessionCount, setMotionCoachSessionCount] = useState<Number>(0)
  const [coachTasksDue, setCoachTasksDue] = useState<Number>(0)
  const [coachTasksOverdue, setCoachTasksOverdue] = useState<Number>(0)

  const globalUser = userInfo && userInfo.roles.some((role) => role === 'devadmin' || role === 'customer_success')

  const getWorkerData = (abortController: AbortController) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/workers/${userInfo?.username}`, {
      method: 'GET',
      signal: abortController.signal,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the /workers endpoint.')
      })
      .then((data: Array<WorkerData>) => {
        const newData = data.map((workerData) => {
          const orderedCoachTasks = workerData.coach_tasks?.sort(function (a: CoachTaskData, b: CoachTaskData) {
            return Date.parse(a.followUpDate) - Date.parse(b.followUpDate)
          })

          if (orderedCoachTasks && orderedCoachTasks.length > 0) {
            const coachTask = orderedCoachTasks.find((item) => item.status !== Completed && item.status !== Ignored)
            if (coachTask) {
              const coachTaskDueDate = format(new Date(coachTask.followUpDate), 'M/dd/yy')

              const dueDateCount = orderedCoachTasks.filter(
                (item) =>
                  format(new Date(item.followUpDate), 'M/dd/yy') === coachTaskDueDate &&
                  item.status !== Completed &&
                  item.status !== Ignored,
              )?.length

              const activityId = allCoachActivities.find((item) => item.label === coachTask.activity)?.id

              return {
                ...workerData,
                coachTask: activityId,
                taskCountOnDueDate: dueDateCount - 1,
                dueDate: coachTask.followUpDate,
              }
            } else {
              return {
                ...workerData,
              }
            }
          } else {
            return {
              ...workerData,
            }
          }
        })
        setWorkers([...newData])
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (workers) {
      const defaultFilteredWorkers =
        userInfo && !userInfo.admin && userInfo.roles.some((role) => role === 'coach')
          ? workers?.filter((worker) => {
              const today = new Date()
              today.setHours(0, 0, 0, 0)
              const dueDate = worker.dueDate ? new Date(worker.dueDate) : new Date()
              dueDate.setHours(0, 0, 0, 0)

              return (
                ['Moderate', 'High', 'Very High'].includes(worker.riskLevel ?? '') &&
                worker.coach === `${userInfo.firstname} ${userInfo.lastname}` &&
                worker.coach_tasks.length > 0 &&
                compareAsc(dueDate, today) <= 0
              )
            })
          : workers

      const defaultFilteredWorkesNoDueDate =
        userInfo && !userInfo.admin && userInfo.roles.some((role) => role === 'coach')
          ? workers?.filter((worker) => {
              return (
                ['Moderate', 'High', 'Very High'].includes(worker.riskLevel ?? '') &&
                worker.coach === `${userInfo.firstname} ${userInfo.lastname}` &&
                worker.coach_tasks.length > 0
              )
            })
          : workers

      const allCoachTasks = defaultFilteredWorkers?.map((x: WorkerData) => x.coach_tasks).flat() ?? []
      const allCoachTasksNoDueDate = defaultFilteredWorkesNoDueDate?.map((x: WorkerData) => x.coach_tasks).flat() ?? []

      const plansDue =
        defaultFilteredWorkesNoDueDate?.filter(
          (item: WorkerData) =>
            typeof item.coach_tasks.find(
              (value: CoachTaskData) => value.activity === CreatePlan && value.status === NotStartedStatus,
            ) !== 'undefined',
        ) || []

      const motionCoachFilteredTasks =
        allCoachTasksNoDueDate?.filter(
          (task: any) =>
            task &&
            (task.activity === CreatePlan || task.activity === PreventureReviewMCData) &&
            task.status === NotStartedStatus,
        ) || []

      const newCoachTasksDue = allCoachTasks.filter(
        (task: any) =>
          task && task.status !== Completed && task.status !== Ignored && isToday(new Date(task.followUpDate)),
      )
      const newCoachTasksOverdue = allCoachTasks.filter(
        (task: any) =>
          task && task.status !== Completed && task.status !== Ignored && isOverdue(new Date(task.followUpDate)),
      )

      setPlansDueCount(plansDue.length)
      setMotionCoachSessionCount(motionCoachFilteredTasks.length)
      setCoachTasksDue(newCoachTasksDue.length)
      setCoachTasksOverdue(newCoachTasksOverdue.length)
    }
  }, [workers])

  useEffect(() => {
    const abortController = new AbortController()
    getWorkerData(abortController)

    return () => {
      abortController.abort()
    }
  }, [])

  if (loading)
    return (
      <Box display="flex" justifyContent="center" mt="25%">
        <CircularProgress />
      </Box>
    )

  return (
    <Grid container direction="column">
      <Grid item xs={3} mt={8}>
        <WorkersHeader
          plansDueCount={plansDueCount}
          motionCoachSessionCount={motionCoachSessionCount}
          coachTasksDue={coachTasksDue}
          coachTasksOverdue={coachTasksOverdue}
        />
      </Grid>
      <Grid item xs={9}>
        <WorkersTable
          workers={workers}
          defaultSort={
            (userInfo && !!userInfo?.roles.includes('devadmin')) || !!userInfo?.roles.includes('customer_success')
              ? 'name'
              : 'dueDate'
          }
          globalUser={globalUser}
        />
      </Grid>
    </Grid>
  )
}

export default Workers
