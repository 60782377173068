import { Grid, Typography } from '@mui/material'
import React from 'react'
import ModelTrainingIcon from '../../shared/icons/model-training-icon'
import PeerwellIcon from '../../shared/icons/peerwell-icon'
import QueryStatsIcon from '../../shared/icons/query-stats-icon'
import SMSIcon from '../../shared/icons/sms-icon'
import InfoCard from '../../shared/info-card'
import { WorkerDetailData } from '../../workers/workers-types'

export interface QuickViewProps {
  mcsTasksToReview: Number
  worker: WorkerDetailData
}

const generateQuickTrainingCardValue = (percentComplete: number, completed: number) => {
  return `${percentComplete || ''}${typeof percentComplete === 'number' ? '%' : '--'} ${
    typeof completed === 'number' ? `(${completed})` : ''
  }`
}

const QuickView = (props: QuickViewProps) => {
  const { mcsTasksToReview, worker } = props
  const trainingModules = worker?.trainingModules

  function getEngagementColor(engagement: string): string {
    if (engagement === 'Poor') return '#EF9A9A'
    else if (engagement === 'Falling') return '#FDD835'
    else if (engagement === 'Good') return '#A5D6A7'
    else return 'black'
  }
  const engagementColor = getEngagementColor(worker?.peerwellEngagement || '')

  return (
    <Grid container>
      <Grid container item spacing={3}>
        <Grid item xs={3}>
          <InfoCard
            title="Movement Coach sets to review"
            value={mcsTasksToReview.toString()}
            valueColor="#8E24AA"
            link={() =>
              worker?.workerId
                ? `${process.env.REACT_APP_PREVENTURE_BASE_URL}/reports/movement-coaches/filters?filter=worker__${worker.workerId}`
                : '/'
            }
            linkText="View Detail"
            icon={<QueryStatsIcon />}
            opensInNewTab
          />
        </Grid>
        <Grid item xs={3}>
          <InfoCard
            title="Peerwell app activity"
            value={worker?.peerwellEngagement || '--'}
            valueColor={engagementColor}
            link={() => worker?.peerwellDeepLink}
            linkText="View Detail"
            opensInNewTab
            // additionalInfo={
            //   <Typography fontSize="14px">
            //      {worker.peerwellStatus}
            //   </Typography>
            // }
            icon={<PeerwellIcon />}
          />
        </Grid>
        <Grid item xs={3}>
          <InfoCard
            title="Training completed"
            value={generateQuickTrainingCardValue(Math.round(trainingModules?.percentComplete), trainingModules?.completed)}
            valueColor="#1D738F"
            link={() => `${process.env.REACT_APP_PREVENTURE_BASE_URL}/training/progress`}
            linkText="View Detail"
            opensInNewTab
            additionalInfo={
              <Typography fontSize="14px" sx={{ whiteSpace: 'nowrap' }}>
                <b>{trainingModules?.enrolled || 0}</b> enrolled &nbsp; &nbsp;
                <b>{trainingModules?.inProgress || 0}</b> in progress
              </Typography>
            }
            icon={<ModelTrainingIcon />}
          />
        </Grid>
        <Grid item xs={3} sx={{ visibility: 'hidden' }}>
          <InfoCard
            title="Messages needing reply"
            value="6"
            valueColor="#3425A0"
            link="/"
            linkText="View all"
            additionalInfo={<Typography fontSize="14px">Not Implemented</Typography>}
            icon={<SMSIcon />}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuickView
