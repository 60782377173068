import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import CompaniesTable from './companies-table'
import { CompanyData, CompanyResponseData } from './companies-types'

const Companies = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [companies, setCompanies] = useState<CompanyData[]>([])

  const getWorkerDetailData = (abortController: AbortController) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/companies/list`, {
      method: 'GET',
      signal: abortController.signal,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the /companies endpoint.')
      })
      .then((data: CompanyResponseData[]) => {
        // Randomizes our limited data set for testing if uncommented
        // setCompanies(
        //   data.map((d: CompanyResponseData) => {
        //     let randomCharCode = Math.floor(Math.random() * 26) + 97
        //     return {
        //       ...d,
        //       coachName: `${d?.coach?.firstName?.[0] ?? ''}${d?.coach?.lastName?.[0] ?? ''}`,
        //       companyName: d.companyName + String.fromCharCode(randomCharCode),
        //       companyTier: d.companyTier + Math.floor(Math.random() * 5),
        //     }
        //   }),
        // )
        setCompanies(
          data.map((company: CompanyResponseData) => {
            return {
              companyName: company.companyName,
              companyId: company.companyId,
              companyTier: company.companyTier,
              coachName: company.coach?.name,
            }
          }),
        )
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    getWorkerDetailData(abortController)

    return () => {
      abortController.abort()
    }
  }, [])

  if (loading)
    return (
      <Box display="flex" justifyContent="center" mt="25%">
        <CircularProgress />
      </Box>
    )

  return (
    <Grid container direction="column">
      <Grid item xs={3} mt={8}></Grid>
      <Grid item xs={9}>
        <CompaniesTable companies={companies} />
      </Grid>
    </Grid>
  )
}

export default Companies
