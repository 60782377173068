import { Box, CircularProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { WorkerDetailData } from '../workers/workers-types'
import MotionCoach from './motion-coach/motion-coach'
import ProfileStrip from './profile-strip'
import { Navigate, useParams } from 'react-router-dom'
import WorkerPlan from './worker-plan/worker-plan'
import QuickView from './quick-view/quick-view'

const WorkerDetails = () => {
  const { workerid } = useParams()
  const [worker, setWorker] = useState<WorkerDetailData>()
  const [loading, setLoading] = useState<boolean>(true)
  const [mcsTasksToReview, setMcsTasksToReview] = useState<number>(0)

  const getWorkerDetailData = (abortController: AbortController) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/worker/${workerid}`, {
      method: 'GET',
      signal: abortController.signal,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the /worker endpoint.')
      })
      .then((data: WorkerDetailData) => {
        setWorker(data)
        setMcsTasksToReview(data.newMovementTaskCount)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const handleMcsTasksToReviewChange = (decrement: boolean) => {
    const newTaskCount = decrement ? mcsTasksToReview - 1 : mcsTasksToReview + 1
    setMcsTasksToReview(newTaskCount)
  }

  useEffect(() => {
    const abortController = new AbortController()
    getWorkerDetailData(abortController)

    return () => {
      abortController.abort()
    }
  }, [])

  if (loading)
    return (
      <Box display="flex" justifyContent="center" mt="25%">
        <CircularProgress />
      </Box>
    )

  if (!workerid) return <Navigate to="/not-found" replace />

  return (
    <Grid container direction="column" sx={{ maxWidth: '1380px' }} spacing={2}>
      {/* remove height on grid container when everything is implemented */}
      <Grid item xs={1}>
        <ProfileStrip worker={worker} />
      </Grid>
      <Grid item xs={5}>
        <QuickView mcsTasksToReview={mcsTasksToReview} worker={worker!} />
      </Grid>
      <Grid container item spacing={3} xs={8} sx={{ overflowY: 'hidden' }}>
        <Grid item xs={3}>
          <MotionCoach worker={worker!} />
        </Grid>
        <Grid item xs={6} sx={{ height: '100%' }}>
          <WorkerPlan
            workerTier={worker?.tier}
            workerid={workerid}
            onMcsTasksToReviewChange={handleMcsTasksToReviewChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ border: '1px black solid', visibility: 'hidden' }}>Right component placeholder</Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WorkerDetails
