import { ChimeSDKMessagingClient } from '@aws-sdk/client-chime-sdk-messaging'
import { DefaultMessagingSession } from 'amazon-chime-sdk-js'
import React from 'react'
import { AutocompleteUser, Channel, LatestReadMessage } from '../components/messages/message-types'

export type ChimeChannelDetailsEvent = {
  Channel: {
    ChannelArn: string
    Name: string
  }
  ChannelMemberships: ChannelMembership[]
  ChannelMessages: ChannelMessages[]
  ReadMarkerTimestamp: string
}

export type ChannelMembership = {
  Member: {
    Name: string
  }
}

export type ChannelMessages = {
  Content: string
  MessageId: string
  CreatedTimestamp: string
  Sender: {
    Arn: string
    Name: string
  }
  Status: {
    Value: string
  }
}

export type ChimeMessageEvent = {
  MessageId: string
  ChannelArn: string
  CreatedTimestamp: string
  Content: string
  Sender: {
    Arn: string
    Name: string
  }
  Status: {
    Value: string
  }
}

export interface ChatCredentials {
  userArn: string
  sessionToken: string
  accessKeyId: string
  secretAccessKey: string
}

export interface IChimeContext {
  messagingClient?: ChimeSDKMessagingClient
  chatCredentials?: ChatCredentials
  messagingSession?: DefaultMessagingSession
  channels: Channel[]
  mostRecentReadMessage?: LatestReadMessage
  getOrCreateChannel: (recipients: AutocompleteUser[], message: string) => Promise<string | null>
  updateUnreadNotifications: (channelArn: string) => Promise<void>
  resetChime: () => void
}
export const ChimeContext = React.createContext<IChimeContext>({
  channels: [],
  getOrCreateChannel: async () => {
    return null
  },
  updateUnreadNotifications: async () => {},
  resetChime: () => {},
})

export type ChatAPIChannel = {
  channelArn: string
  channelName: string
  lastMessageTimestamp?: Date
  readMarkerTimestamp?: Date
  members: ChatAPIChannelMember[]
}

export type ChatAPIChannelMember = {
  userid: string
  fullname: string
  roles: string[]
  companyId: string
  companyName: string
}

export type UpdatedChannel = {
  channelArn: string
  unreadMessageCount: number
  lastMessageTimestamp?: Date
  previewMessage?: string
  readMarkerTimestamp?: Date
}
