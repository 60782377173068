import { ReactNode, useMemo, useReducer } from 'react'
import { AuthContext, AuthenticationContext, UserInfo } from './authentication-context'

interface AuthProviderProps {
  children: ReactNode
}

export interface AuthContextState {
  userInfo: UserInfo | undefined
  isAuthenticated: boolean
  jwt?: string
}

const AuthenticationContextProvider = (props: AuthProviderProps) => {
  const [state, setAuthenticationState] = useReducer(
    (currentState: AuthContextState, newState: Partial<AuthContextState>) => {
      return {
        ...currentState,
        ...newState,
      }
    },
    {
      userInfo: undefined,
      isAuthenticated: false,
      jwt: undefined,
    },
  )

  const { children } = props

  const values: AuthContext | null = useMemo(() => {
    return {
      ...state,
      setAuthenticationState,
    }
  }, [state])

  return <AuthenticationContext.Provider value={values}>{children}</AuthenticationContext.Provider>
}

export default AuthenticationContextProvider
