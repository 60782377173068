import { Paper, Typography, Divider, Box } from '@mui/material'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface InfoCardProps {
  title: string
  value: string
  valueColor?: string
  icon: JSX.Element
  link?: string | (() => string)
  linkText?: string
  additionalInfo?: ReactNode
  disableLink?: boolean
  opensInNewTab?: boolean
}

const InfoCard = (props: InfoCardProps) => {
  const { title, value, link, linkText, icon, valueColor, additionalInfo, disableLink, opensInNewTab } = props
  const linkOutput = () => {
    if (disableLink) {
      return (
        <Typography align="right" variant="body2" pb={1}>
          {linkText}
        </Typography>
      )
    } else if (link) {
      return (
        <Typography align="right" variant="body2" pb={1}>
          {typeof link === 'string' ? (
            <Link to={link}>{linkText}</Link>
          ) : (
            <a href={link()} target={opensInNewTab ? '_blank' : ''}>
              {linkText}
            </a>
          )}
        </Typography>
      )
    } else {
      return <Box mt={6} />
    }
  }
  return (
    <Box>
      {icon}
      <Paper elevation={2} sx={{ px: '1rem', pt: '1rem', borderRadius: '10px' }}>
        <Typography align="right" sx={{ fontSize: '14px' }}>
          {title}
        </Typography>
        <Typography sx={{ color: valueColor ?? 'inherit' }} align="right" pb={2} fontSize={'24px'} fontWeight={500}>
          {value}
        </Typography>
        <Divider />
        <Box pt={2} pb={1} justifyContent="space-between" display={'flex'}>
          {additionalInfo ?? <Box />}
          {linkOutput()}
        </Box>
      </Paper>
    </Box>
  )
}

export default InfoCard
