import { Box } from '@mui/material'
import { ReactComponent as PrevLogo } from '../../../images/icons/Bardavon-Prevention-Logo.svg'
import React from 'react'

const AppIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        width: '140px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0',
        position: 'absolute',
        marginTop: '-1rem',
      }}
    >
      <PrevLogo />
    </Box>
  )
}

export default AppIcon
