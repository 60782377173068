import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import { Trend } from '../../workers/workers-types'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'

export interface MCAverageRiskScoreData {
  trend?: Trend
}

export interface MCAverageRiskScoreItemData {
  label: string
  score?: number
}

const MCAverageRiskScoreItem = (props: MCAverageRiskScoreItemData) => {
  const { label, score } = props

  return (
    <Grid container direction="column" alignItems="center" spacing={0}>
      <Grid item>
        <Typography fontSize={'12px'} fontWeight={300}>
          {' '}
          {label}
        </Typography>
      </Grid>
      <Grid item>
        {score ? (
          score < 0 ? (
            <ArrowDownwardOutlinedIcon style={{ color: '#FFFFFF', backgroundColor: '#4CAF50' }} />
          ) : score > 0 ? (
            <ArrowUpwardOutlinedIcon style={{ color: '#FFFFFF', backgroundColor: '#D32F2F' }} />
          ) : (
            <ArrowForwardOutlinedIcon sx={{ color: '#FFFFFF', backgroundColor: '#000000' }} />
          )
        ) : (
          <RemoveOutlinedIcon sx={{ color: '#FFFFFF', backgroundColor: '#BFBFBF' }} />
        )}
      </Grid>
    </Grid>
  )
}

const MCAverageRiskScore = (props: MCAverageRiskScoreData) => {
  const { trend } = props

  const handleClick = () => {
    console.log('handle click')
  }

  return (
    <Grid container direction="column" spacing={0} sx={{ margin: '0px' }}>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" sx={{ margin: '0px' }}>
          <Grid item>
            <MCAverageRiskScoreItem label="Overall" score={trend?.overallTrend} />
          </Grid>
          <Grid item>
            <Box sx={{ width: '0.8rem', height: '0.8rem' }} />
          </Grid>
          <Grid item>
            <MCAverageRiskScoreItem label="Arm" score={trend?.armTrend} />
          </Grid>
          <Grid item>
            <Box sx={{ width: '1rem', height: '1rem' }} />
          </Grid>
          <Grid item>
            <MCAverageRiskScoreItem label="Back" score={trend?.backTrend} />
          </Grid>
          <Grid item>
            <Box sx={{ width: '1rem', height: '1rem' }} />
          </Grid>
          <Grid item>
            <MCAverageRiskScoreItem label="Leg" score={trend?.legTrend} />
          </Grid>
          <Grid item>
            <Box sx={{ width: '1rem', height: '1rem' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ marginTop: '4px' }}>
        {trend?.overallTrend && trend?.armTrend && trend?.backTrend && trend?.legTrend ? (
          <Typography fontSize={'12px'} fontWeight={300}>
            Last 5 sessions vs. all
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#D32F2F' }}>Not enough data.</Typography>
        )}
      </Grid>
      <Grid item sx={{ marginTop: '4px' }}>
        <Divider sx={{ marginBottom: '8px', marginRight: '5px' }} />
      </Grid>
    </Grid>
  )
}

export default MCAverageRiskScore
