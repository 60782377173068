import {
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Button,
  Chip,
  Typography,
  SelectChangeEvent,
  Grid,
} from '@mui/material'
import { ChangeEvent, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { CoachTaskRecord, UpdateCoachTask, UpdateCoachTaskResponse } from '../worker-details-types'
import { allCoachActivities, coachActivities, coachStatuses } from './coach-tasks-definitions'
import { DatePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import { isDateEqual, isOverdue } from './coach-utilities'
import { AuthenticationContext } from '../../../contexts/authentication-context'
import { ProgramData } from '../../workers/workers-types'

interface CoachTaskProps {
  taskId: string
  task: CoachTaskRecord
  allowEdit: boolean
  setEditedTaskId: (taskId: string | undefined) => void
  setLoading: (loading: boolean) => void
  updateCoachTasks: (updatedTask: UpdateCoachTaskResponse) => void
  onMcsTasksToReviewChange: (decrement: boolean) => void
}
const CoachTask = (props: CoachTaskProps) => {
  const { task, taskId, allowEdit, setEditedTaskId, setLoading, updateCoachTasks } = props

  const [note, setNote] = useState<string>('')
  const [dueDate, setDueDate] = useState<Date>(new Date(task.dueDate))
  const [statusSelect, setStatusSelect] = useState<string>(
    coachStatuses.find((status) => status.label === task.status)?.id as string,
  )
  const [programs, setPrograms] = useState<Array<ProgramData>>([])
  const [selectedProgram, setSelectedProgram] = useState<string>(
    task.notes.length > 0 ? task.notes[0].programId ?? '' : '',
  )

  const { userInfo } = useContext(AuthenticationContext)

  const handleExpand = (expand: boolean) => {
    if (!expand) {
      setNote('')
      setStatusSelect(coachStatuses.find((status) => status.label === task.status)?.id as string)
      setDueDate(new Date(task.dueDate))
    }
    setEditedTaskId(expand ? taskId : undefined)
  }

  const handleDateChange = (date: Date | null) => {
    if (date !== null) {
      const newDueDate = date
      newDueDate.setHours(0, 0, 0, 0)
      setDueDate(newDueDate)
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    getPrograms(abortController)

    return () => {
      abortController.abort()
    }
  }, [])

  const getPrograms = (abortController: AbortController) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/cmp_peerwell/programs`, {
      method: 'GET',
      signal: abortController.signal,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the /worker endpoint.')
      })
      .then((data: Array<ProgramData>) => {
        setPrograms(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const handleStatusChange = (event: SelectChangeEvent) => {
    const newSelectStatus = event.target.value
    if (newSelectStatus) setStatusSelect(newSelectStatus)
  }

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    event.preventDefault()
    setLoading(true)
    const name = `${userInfo?.firstname} ${userInfo?.lastname ?? ''}`
    const updatedValues: UpdateCoachTask = {
      taskId: taskId,
      dueDate: dueDate.toISOString(),
      status: coachStatuses.find((status) => status.id === statusSelect?.toString())?.label,
      note: note.length > 0 ? `${note} (${name.trim()})` : undefined,
      programId: selectedProgram.length === 0 ? undefined : selectedProgram,
    }
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/coach/task`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...updatedValues }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the PATCH /coach/task endpoint.')
      })
      .then((responseData) => {
        updateCoachTasks(responseData)
        setNote('')
        // Update review task count only when task status changes to/from "Not started"
        if (
          updatedValues.status !== task.status &&
          (updatedValues.status === 'Not started' || task.status === 'Not started') &&
          (task.activity.toString() === 'Create Plan' || task.activity.toString() === 'Preventure: Review MC data')
        ) {
          props.onMcsTasksToReviewChange(updatedValues.status === 'Not started' ? false : true)
        }
        setLoading(false)
      })
      .catch((error) => {
        //TODO: Figure out what to do with errors
        setLoading(false)
      })
  }

  const noteUpdate = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.currentTarget.value)
  }

  const saveDisabled = useMemo(() => {
    if (!isOverdue(dueDate) && !isDateEqual(new Date(task.dueDate), dueDate)) return false
    else if (
      statusSelect !== 'overdue' &&
      statusSelect !== (coachStatuses.find((status) => status.label === task.status)?.id as string)
    )
      return false

    const taskProgram = task.notes.length > 0 ? task.notes[0].programId ?? '' : ''
    if (selectedProgram !== taskProgram) return false
    if (note.length > 0) return false
    return true
  }, [dueDate, selectedProgram, statusSelect, note])

  const isAutoCreated =
    task.activity.toString() == 'Create Plan' || task.activity.toString() == 'Preventure: Review MC data'
  const activitiesList = isAutoCreated ? allCoachActivities : coachActivities

  // console.log(task)

  return allowEdit ? (
    <Grid item container spacing={1} component={'form'} autoComplete="off" onSubmit={handleSubmit}>
      <Grid item xs={5}>
        <FormControl fullWidth disabled required>
          <InputLabel size="small">Coach task</InputLabel>
          <Select
            size="small"
            input={<OutlinedInput label="Coach task" />}
            defaultValue={activitiesList.find((activity) => activity.label === task.activity)?.id}
          >
            {activitiesList.map((activity) => (
              <MenuItem key={activity.id} value={activity.id}>
                {activity.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth required>
          {statusSelect === 'completed' ? (
            <DatePicker
              disabled
              label="Due Date"
              onChange={() => {}}
              inputFormat="MM/dd/yy"
              mask="__/__/__"
              value={dueDate}
              renderInput={(params) => {
                return (
                  <TextField
                    required
                    name="dueDate"
                    type="date"
                    size="small"
                    helperText="Format: MM/DD/YY"
                    {...params}
                  />
                )
              }}
            />
          ) : (
            <DatePicker
              disablePast
              label="Due Date"
              onChange={handleDateChange}
              inputFormat="MM/dd/yy"
              mask="__/__/__"
              value={dueDate}
              renderInput={(params) => {
                return (
                  <TextField
                    required
                    name="dueDate"
                    type="date"
                    size="small"
                    helperText="Format: MM/DD/YY"
                    {...params}
                  />
                )
              }}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth required>
          <InputLabel size="small" htmlFor="status">
            Status
          </InputLabel>
          <Select
            data-testid={'status'}
            name="status"
            size="small"
            label="status"
            input={<OutlinedInput label="Status" />}
            value={statusSelect}
            onChange={handleStatusChange}
          >
            {coachStatuses.map((status) => (
              <MenuItem
                disabled={isAutoCreated && status.id === 'ignored' ? true : false}
                key={status.id}
                value={status.id}
              >
                {status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(false)}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </Grid>
      {activitiesList.find((activity) => activity.label === task.activity)?.id === 'peerwell_assign_program' ? (
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel size="small">Program</InputLabel>
            <Select
              name="program"
              size="small"
              defaultValue=""
              input={<OutlinedInput label="Program" />}
              value={selectedProgram}
              onChange={(event: SelectChangeEvent) => setSelectedProgram(event.target.value)}
            >
              {programs.map((program) => (
                <MenuItem key={program.programId} value={program.programId}>
                  {program.programName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ) : null}
      <Grid item xs={11} borderBottom={'1px solid rgb(224, 224, 224)'}>
        <TextField
          size="small"
          multiline
          fullWidth
          variant="outlined"
          inputProps={{
            maxLength: 500,
          }}
          label="Notes"
          name="note"
          value={note}
          onChange={noteUpdate}
          helperText={`${note?.length ?? 0}/500`}
          sx={{
            '& .MuiFormHelperText-root': {
              color: '#000000',
              position: 'absolute',
              top: '-12px',
              right: '-5px',
              background: 'white',
              zIndex: 1,
            },
          }}
        ></TextField>
        <Box justifyContent={'flex-end'} display={'flex'}>
          <Button variant="text" onClick={() => handleExpand(false)}>
            Cancel
          </Button>
          <Button variant="text" type="submit" name="update" disabled={saveDisabled}>
            Save
          </Button>
        </Box>
        <Box sx={{ maxHeight: '215px', width: '100%', overflow: 'scroll' }}>
          <Typography fontSize={'14px'} fontWeight={'500'} mb={'1.5rem'}>
            NOTES
          </Typography>
          {task.notes.map((taskNote) => (
            <Box key={taskNote.noteId}>
              <Typography fontSize={'14px'} fontWeight={500}>
                {format(new Date(taskNote.createdDate), 'M/dd/yy')}{' '}
                {taskNote.programId?.length === 0
                  ? ''
                  : programs.find((program) => program.programId === taskNote.programId)?.programName}{' '}
                - Status: {taskNote.status}
              </Typography>
              <Typography paragraph fontSize={'14px'}>
                {taskNote.body}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Grid item container spacing={1}>
      <Grid item xs={5} alignItems="center" borderBottom={'1px solid rgb(224, 224, 224)'}>
        <Typography sx={{ textDecoration: statusSelect === 'completed' ? 'line-through' : 'none', fontSize: '14px' }}>
          {task.activity}
        </Typography>
      </Grid>
      <Grid item xs={3} alignItems="center" borderBottom={'1px solid rgb(224, 224, 224)'}>
        {isOverdue(dueDate) && statusSelect !== 'completed' && statusSelect !== 'ignored' ? (
          <>
            <Typography
              sx={{
                color: '#D32F2F',
                fontSize: '14px',
              }}
            >
              {format(dueDate, 'M/dd/yy')}
            </Typography>
            <Typography
              sx={{
                pl: '2px',
                color: '#D32F2F',
                fontSize: '10px',
              }}
            >
              Overdue!
            </Typography>
          </>
        ) : (
          <Typography
            sx={{
              textDecoration: statusSelect === 'completed' ? 'line-through' : 'none',
              fontSize: '14px',
            }}
          >
            {format(dueDate, 'M/dd/yy')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3} alignItems="center" borderBottom={'1px solid rgb(224, 224, 224)'}>
        <Chip
          size="small"
          sx={{
            ...coachStatuses.find((status) => status.id === statusSelect)?.extraProps?.sx,
            width: '90px',
            fontSize: '13px',
          }}
          label={task.status}
        />
      </Grid>
      <Grid item xs={1} borderBottom={'1px solid rgb(224, 224, 224)'}>
        <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(true)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
export default CoachTask
