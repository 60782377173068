import { Box } from '@mui/material'
import { ReactComponent as CheckCircle } from '../../../images/icons/check_circle.svg'
import React from 'react'

const CheckCircleIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#1D738F',
        width: '67px',
        height: '69px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        marginLeft: '1rem',
        position: 'absolute',
        marginTop: '-1rem',
      }}
    >
      <CheckCircle />
    </Box>
  )
}

export default CheckCircleIcon
