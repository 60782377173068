import React from 'react'

export enum LoadingState {
  Ready,
  Loading,
  Authenticating,
  LoadingChatCredentials,
  GetUserChannels,
  CreateMessageSession,
  Unauthorized,
  Error,
  SetDefaultParams,
  LoginRoute,
}

export interface ILoadingContext {
  loading: LoadingState
  setLoading: (loading: LoadingState) => void
}
export const LoadingContext = React.createContext<ILoadingContext>({
  loading: LoadingState.Loading,
  setLoading: () => {},
})
