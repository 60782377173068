export interface Column {
    id: 'riskLevel' | 'trend' | 'name' | 'coachTask' | 'dueDate' | 'company' | 'tier' | 'coach'
    label: string
    defaultValue?: string
}

export const columns: readonly Column[] = [
    { id: 'riskLevel', label: 'Risk Level', defaultValue: '--' },
    { id: 'trend', label: 'Trend', defaultValue: '--' },
    { id: 'name', label: 'Name' },
    { id: 'coachTask', label: 'Task', defaultValue: '--' },
    { id: 'dueDate', label: 'Due Date', defaultValue: '--' },
    { id: 'company', label: 'Company' },
    { id: 'tier', label: 'Tier' },
    { id: 'coach', label: 'Coach' },
]