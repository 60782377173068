import { Box, Typography } from '@mui/material'
import TaskIcon from '../../shared/icons/task-icon'
import { allCoachActivities } from '../../worker-details/worker-plan/coach-tasks-definitions'

export interface ActivityCellData {
  type: string
  count: number
}

const ActivityCell = (props: ActivityCellData) => {
  const { type, count } = props

  const displayLabel = allCoachActivities.find((item) => item.id === type)?.shortLabel
  const activityLabel = displayLabel ? (count > 0 ? `${displayLabel} + ${count}` : displayLabel) : ''

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '0px' }}>
      <TaskIcon type={type} />
      <Box sx={{ m: 0.25 }} />
      <Typography fontWeight={500} fontSize={'14px'}>
        {activityLabel}
      </Typography>
    </Box>
  )
}

export default ActivityCell
