import React from 'react'

export interface IRouteContext {
  defaultRoute: string
  defaultParams: string
  initialLoad: boolean
  setDefaultParams: (newDefaultParams: string) => void
  setInitialLoad: (isInitialLoad?: boolean) => void
  resetDefaultFilter: () => string
  clearDefaultFilter: () => void
}
export const RouteContext = React.createContext<IRouteContext>({
  defaultRoute: '/workers',
  defaultParams: '',
  initialLoad: true,
  setDefaultParams: () => {},
  setInitialLoad: () => {},
  resetDefaultFilter: () => '',
  clearDefaultFilter: () => {},
})
