import { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import MessageInbox from './message-inbox'
import ChatWindow from './chat-window'
import { ChimeContext } from '../../contexts/chime-context'
import { AutocompleteUser, Channel, ChatUser, User } from './message-types'
import {
  ListChannelMessagesCommand,
  ListChannelMessagesCommandInput,
  SendChannelMessageCommand,
} from '@aws-sdk/client-chime-sdk-messaging'
import NewChatWindow from './new-chat-window'
import { compareAsc } from 'date-fns'
import { AuthenticationContext } from '../../contexts/authentication-context'
import { debounce } from 'throttle-debounce'

const Messages = () => {
  const [selectedChannelArn, setSelectedChannelArn] = useState<string>()
  const [currentChannel, setCurrentChannel] = useState<Channel>()
  const [searchTerms, setSearchTerms] = useState<string>('')
  const [searchContacts, setSearchContacts] = useState<ChatUser[]>([])
  const [searching, setSearching] = useState<boolean>(false)
  const [selectedContacts, setSelectedContacts] = useState<AutocompleteUser[]>([])
  const [newChannelArn, setNewChannelArn] = useState<string>()
  const [message, setMessage] = useState<string>('')
  const [autofocus, setAutofocus] = useState<boolean>(true)
  const {
    channels,
    chatCredentials,
    messagingClient,
    mostRecentReadMessage,
    updateUnreadNotifications,
    getOrCreateChannel,
  } = useContext(ChimeContext)
  const { jwt, userInfo } = useContext(AuthenticationContext)

  const handleCurrentChannelChange = (channel?: Channel) => {
    setCurrentChannel(channel)
  }

  const handleSelectedChannelArnChange = (channelArn?: string) => {
    setSelectedChannelArn(channelArn)
  }

  const loadInitialMessages = async () => {
    const selectedChannel = channels.find((channel) => channel.channelArn === selectedChannelArn)
    if (selectedChannel && chatCredentials && messagingClient && selectedChannelArn) {
      const newSelectedChannel = {
        ...selectedChannel,
      }
      if (selectedChannel?.unreadMessageCount > 0) {
        await updateUnreadNotifications(selectedChannelArn)
      }
      const commandPayload: ListChannelMessagesCommandInput = {
        ChannelArn: selectedChannel.channelArn,
        ChimeBearer: chatCredentials.userArn,
      }

      const messageListResponse = await messagingClient.send(new ListChannelMessagesCommand(commandPayload))
      const messages =
        messageListResponse?.ChannelMessages?.map((chimeMessage) => {
          const userArnParts = (chimeMessage.Sender?.Arn as string).split('/')
          return {
            messageId: chimeMessage.MessageId as string,
            createdTimestamp: chimeMessage.CreatedTimestamp as Date,
            content: chimeMessage.Content ?? '',
            sender: {
              userid: userArnParts[userArnParts.length - 1],
              name: chimeMessage.Sender?.Name as string,
            },
            status: chimeMessage?.Status?.Value as string,
          }
        }) ?? []
      newSelectedChannel.messages = [...messages].sort((a, b) => compareAsc(a.createdTimestamp, b.createdTimestamp))
      newSelectedChannel.nextMessagePageToken = messageListResponse.NextToken
      setCurrentChannel(newSelectedChannel)
    }
  }

  const userSearch = debounce(250, (abortController: AbortController) => {
    if (searchTerms && searchTerms.length > 0 && !searching) {
      setSearching(true)
      fetch(`${process.env.REACT_APP_CHAT_API_BASE_URL}/user/${userInfo?.userId}/contacts?query=${searchTerms}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        signal: abortController.signal,
      })
        .then((response) => {
          if (response.ok) return response.json()
          else {
            throw Error('An unknown error occurred.')
          }
        })
        .then((data) => {
          setSearchContacts(data.members ? [...data.members] : [])
          setSearching(false)
        })
        .catch((err) => {
          setSearching(false)
          setSearchContacts([])
        })
    } else if (!searching && !searchTerms && searchContacts.length > 0) {
      setSearchContacts([])
    }
  })

  const sendMessage = async () => {
    if (message && messagingClient && currentChannel) {
      const command = new SendChannelMessageCommand({
        ChannelArn: currentChannel.channelArn,
        ChimeBearer: chatCredentials?.userArn,
        Content: message,
        Type: 'STANDARD',
        Persistence: 'PERSISTENT',
      })
      const messageResponse = await messagingClient.send(command)
      if (messageResponse) {
        setMessage('')
      }

      return messageResponse
    }
    return
  }

  const getOrCreateChannelAndSendMessage = () => {
    if (userInfo?.userId && selectedContacts.length && message) {
      getOrCreateChannel(selectedContacts, message).then((newlyAddedChannel) => {
        if (newlyAddedChannel) {
          setSelectedContacts([])
          setNewChannelArn(newlyAddedChannel)
          setMessage('')
        }
      })
    }
  }

  useEffect(() => {
    if (mostRecentReadMessage) setSelectedChannelArn(mostRecentReadMessage.channelArn)
  }, [mostRecentReadMessage])

  useEffect(() => {
    const newCurrentChannel = channels.find((channel) => channel.channelArn === newChannelArn)
    if (newChannelArn && newCurrentChannel && channels.some((channel) => channel.channelArn === newChannelArn)) {
      setSelectedChannelArn(newChannelArn)
      setNewChannelArn(undefined)
    }
  }, [newChannelArn, channels])

  useEffect(() => {
    const abortController = new AbortController()
    userSearch(abortController)
    return () => {
      abortController.abort()
    }
  }, [searchTerms])

  //The only data we want to update from channel context is when a new message is received.  Everything else can be updated
  //via messaging session calls + local state changes.
  useEffect(() => {
    if (channels && currentChannel) {
      const updatedChannel = channels.find((channel) => channel.channelArn === currentChannel.channelArn)
      if (updatedChannel) {
        setCurrentChannel({
          ...updatedChannel,
          nextMessagePageToken: currentChannel.nextMessagePageToken,
          messages: [
            ...updatedChannel.messages,
            ...currentChannel.messages.filter(
              (currentChannelMessages) =>
                !updatedChannel.messages.some(
                  (updatedMessage) => updatedMessage.messageId === currentChannelMessages.messageId,
                ),
            ),
          ],
        })
      }
    }
  }, [channels])

  useEffect(() => {
    if (selectedChannelArn) {
      loadInitialMessages()
    }
  }, [selectedChannelArn])

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '1380px',
        height: 'calc(100vh - 73px - 3rem)',
        maxHeight: '800px',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          width: '320px',
          borderRight: '1px solid #979797',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
        }}
      >
        <MessageInbox
          setSelectedChannelArn={handleSelectedChannelArnChange}
          selectedChannelArn={selectedChannelArn}
          setAutofocus={(newAutoFocus: boolean) => {
            setAutofocus(newAutoFocus)
          }}
        />
      </Box>
      <Box
        sx={{
          width: '1060px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {selectedChannelArn && currentChannel ? (
          <ChatWindow
            currentChannel={currentChannel}
            updateCurrentChannel={handleCurrentChannelChange}
            message={message}
            setMessage={(newMessage: string) => setMessage(newMessage ?? '')}
            sendMessage={sendMessage}
          />
        ) : (
          <NewChatWindow
            searchTerms={searchTerms}
            userSearch={(newSearchTerms) => {
              setSearchTerms(newSearchTerms)
            }}
            searchContacts={searchContacts}
            searching={searching}
            selectedContacts={selectedContacts}
            setSelectedContacts={(newSelectedUsers) => setSelectedContacts([...newSelectedUsers])}
            message={message}
            setMessage={(newMessage: string) => setMessage(newMessage ?? '')}
            getOrCreateChannelAndSendMessage={getOrCreateChannelAndSendMessage}
            clearUserSearch={() => {
              setSearchTerms('')
            }}
            autofocus={autofocus}
          />
        )}
      </Box>
    </Box>
  )
}

export default Messages
