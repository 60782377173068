import { Box } from '@mui/material'
import { ReactComponent as Peerwell } from '../../../images/icons/peerwell.svg'
import React from 'react'

const PeerwellIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        width: '67px',
        height: '69px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        marginLeft: '1rem',
        position: 'absolute',
        marginTop: '-1rem',
      }}
    >
      <Peerwell />
    </Box>
  )
}

export default PeerwellIcon
