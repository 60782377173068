import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { CompanyDetailData } from '../workers/workers-types'
import WbTwilightOutlinedIcon from '@mui/icons-material/WbTwilightOutlined'
import CompanyContacts from './company-contacts'
import CompanyTier from './company-tier'

const CompanyDetails = () => {
  const { companyid } = useParams()
  const [company, setCompany] = useState<CompanyDetailData>()
  const [loading, setLoading] = useState<boolean>(false)

  const getCompanyDetailData = (abortController: AbortController) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/company/${companyid}`, {
      method: 'GET',
      signal: abortController.signal,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the /worker endpoint.')
      })
      .then((data: CompanyDetailData) => {
        setCompany(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    getCompanyDetailData(abortController)

    return () => {
      abortController.abort()
    }
  }, [])

  if (loading)
    return (
      <Box display="flex" justifyContent="center" mt="25%">
        <CircularProgress />
      </Box>
    )

  if (!companyid) return <Navigate to="/not-found" replace />

  return (
    <Grid container direction="column" sx={{ marginTop: '10px', padding: '0px' }} spacing={2}>
      <Grid item xs={1}>
        <Typography data-testid="Company Name" sx={{ fontSize: '18px', fontWeight: 500 }}>
          {company?.companyName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          <Grid item xs={3}>
            <Paper sx={{ height: '550px', padding: '0px', overflow: 'scroll' }}>
              <CompanyContacts company={company} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ height: '550px', overflow: 'scroll', backgroundColor: '#E6F1FA' }}>
              <Grid container height="100%" alignItems="center" justifyContent="center" direction="column" spacing={0}>
                <Grid item>
                  <WbTwilightOutlinedIcon sx={{ color: '#9D98BA' }} />
                </Grid>
                <Grid item>
                  <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#9D98BA' }}>Coming soon</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper sx={{ height: '550px', overflow: 'scroll' }}>
              <CompanyTier company={company} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CompanyDetails
