import { Autocomplete, Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { AutocompleteUser, ChatUser, ROLE_MAPPINGS, Role, SHORTHAND_ROLE_MAPPINGS } from './message-types'
import { CircularProgress } from '@mui/material'
import Avatar from '../shared/avatar'
import { SyntheticEvent, useEffect } from 'react'

export interface NewChatWindowProps {
  searchTerms: string
  userSearch: (searchTerm: string) => void
  searchContacts: ChatUser[]
  searching: boolean
  selectedContacts: AutocompleteUser[]
  message: string
  setMessage: (newMessage: string) => void
  setSelectedContacts: (newSelectedUsers: AutocompleteUser[]) => void
  getOrCreateChannelAndSendMessage: () => void
  clearUserSearch: () => void
  autofocus: boolean
}

const NewChatWindow = (chatProps: NewChatWindowProps) => {
  const {
    searchTerms,
    userSearch,
    searchContacts,
    searching,
    selectedContacts,
    message,
    setMessage,
    setSelectedContacts,
    getOrCreateChannelAndSendMessage,
    clearUserSearch,
    autofocus,
  } = chatProps

  useEffect(() => {
    if (searchTerms.length === 0 && message.length > 0) {
      setMessage('')
    }
  }, [searchTerms, message])

  return (
    <Box sx={{ p: 2, display: 'flex', alignItems: 'center', borderBottom: '1px solid #979797', width: '100%' }}>
      <Typography sx={{ pr: 2 }}>To:</Typography>
      <Autocomplete
        multiple
        disablePortal
        disableClearable
        filterOptions={(x) => x}
        id="search-contacts"
        value={selectedContacts}
        options={searchContacts.map((result) => {
          return {
            label: result.fullname,
            id: result.userid,
            role: result.roles[0],
            company: result.companyName,
          } as AutocompleteUser
        })}
        isOptionEqualToValue={(option, value) => (value.id && option.id ? option.id === value.id : false)}
        onChange={(event: SyntheticEvent<Element, Event>, values: AutocompleteUser[]) => {
          setSelectedContacts(values)
        }}
        renderTags={(values: AutocompleteUser[]) => {
          return (
            <>
              {values.map((value, index) => {
                if (values.length > 1) {
                  const nameParts = value.label.split(' ')
                  const shortendName = `${nameParts[0]} ${nameParts[1][0]}.`
                  const isLastIndex = index === values.length - 1
                  return (
                    <Box key={value.id} display="flex" justifyContent={'flex-start'} alignItems={'center'}>
                      <Typography fontWeight={500} mr={'4px'} fontSize={'14px'}>{`${shortendName} (${
                        SHORTHAND_ROLE_MAPPINGS[value.role] ?? ''
                      })`}</Typography>
                      <Typography fontSize={'14px'} mr={isLastIndex ? 0 : 0.5}>{`- ${value.company ?? ''}${
                        !isLastIndex ? ',' : ''
                      }`}</Typography>
                    </Box>
                  )
                }
                return (
                  <Box key={value.id} display="flex" justifyContent={'flex-start'} alignItems={'center'}>
                    <Typography fontWeight={500} mr={'4px'} fontSize={'14px'}>{`${value.label} (${
                      ROLE_MAPPINGS[value.role] ?? ''
                    })`}</Typography>
                    <Typography fontSize={'14px'}>{`- ${value.company ?? ''}`}</Typography>
                  </Box>
                )
              })}
            </>
          )
        }}
        filterSelectedOptions
        renderOption={(props, option) => (
          <li {...props}>
            <Box key={option.id} display="flex" justifyContent={'flex-start'} alignItems={'center'}>
              <Avatar
                displayText={option.label}
                altText={option.label}
                role={ROLE_MAPPINGS[option.role] as Role}
                customStyle={{ mr: 1 }}
              />
              <Box display="flex" flexDirection="column">
                <Typography fontWeight={500} mr={'4px'} fontSize={'14px'}>{`${option.label} (${
                  ROLE_MAPPINGS[option.role] ?? ''
                })`}</Typography>
                <Typography fontSize={'14px'}>{option.company ?? ''}</Typography>
              </Box>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autofocus}
            size="small"
            variant="outlined"
            name="recipient"
            onChange={(event) => userSearch(event.target.value ?? '')}
            sx={{ width: '820px' }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {searching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Button
        variant="text"
        onClick={() => {
          setSelectedContacts([])
          clearUserSearch()
        }}
        sx={{ visibility: selectedContacts.length ? 'visible' : 'hidden' }}
      >
        Cancel
      </Button>
      {selectedContacts.length > 0 ? (
        <Box
          sx={{
            pl: '25px',
            pr: '100px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            background: '#FFFFFF',
          }}
        >
          <TextField
            fullWidth
            multiline
            maxRows={10}
            id="message"
            label="Message"
            name="message"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value)
            }}
            inputProps={{
              maxLength: 1500,
            }}
            sx={{
              '& .MuiFormHelperText-root': {
                color: '#000000',
                position: 'absolute',
                top: '-12px',
                right: '-5px',
                background: 'white',
                zIndex: 1,
              },
            }}
            helperText={`${message?.length ?? 0}/1500`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="send message"
                    edge="end"
                    sx={{
                      width: '24px',
                      height: '24px',
                      backgroundColor: '#0B78D0',
                      mr: 0.5,
                      zIndex: 2,
                      '&:hover': {
                        backgroundColor: '#0B78D0',
                      },
                    }}
                    onClick={getOrCreateChannelAndSendMessage}
                  >
                    <ArrowUpwardIcon sx={{ color: 'white' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default NewChatWindow
