import { Box } from '@mui/material'
import { ReactComponent as ModelTraining } from '../../../images/icons/model_training.svg'
import React from 'react'

const ModelTrainingIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#04579A',
        width: '67px',
        height: '69px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        marginLeft: '1rem',
        position: 'absolute',
        marginTop: '-1rem',
      }}
    >
      <ModelTraining />
    </Box>
  )
}

export default ModelTrainingIcon
