import { Avatar, SxProps, Theme } from '@mui/material'
import { Role } from '../messages/message-types'

interface StyledAvatarProps {
  role?: Role
  displayText: string
  altText: string
  customStyle?: SxProps<Theme>
}

const StyledAvatar = (props: StyledAvatarProps) => {
  const { displayText, altText, role, customStyle } = props

  const roleToColor = () => {
    switch (role) {
      case 'Coach':
        return {
          backgroundColor: '#FFFFFF',
          color: '#000000',
          border: '1px solid #000000',
        }
      case 'Worker':
        return {
          backgroundColor: '#C1DCF3',
          color: '#000000',
          border: 'none',
        }
      case 'Safety Pro':
        return {
          backgroundColor: '#2F2F2F',
          color: '#FFFFFF',
          border: 'none',
        }
      default:
        return {
          backgroundColor: '#FFFFFF',
          color: '#000000',
          border: '1px solid #000000',
        }
    }
  }

  const stringAvatar = () => {
    let text = displayText
    let style = { fontSize: '14px', height: '40px', width: '40px', backgroundColor: '#04579A', color: '#FFFFFF' }
    if (isNaN(parseInt(displayText))) {
      const displayTextParts = displayText.split(' ')
      text = displayTextParts
        .map((textPart) => textPart[0])
        .join('')
        .toUpperCase()
      style = { ...style, ...roleToColor() }
    }
    if (customStyle) {
      return {
        sx: { ...style, ...customStyle },
        children: text,
      }
    }
    return {
      sx: style,
      children: text,
    }
  }

  return <Avatar alt={altText} {...stringAvatar()} />
}

export default StyledAvatar
