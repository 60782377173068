import { ReactComponent as Peerwell } from '../../../images/icons/tasks/peerwell_greyscale_bug.svg'
import { ReactComponent as AssignmentAdd } from '../../../images/icons/tasks/assignment_add_greyscale.svg'
import { ReactComponent as SensorOccupied } from '../../../images/icons/tasks/sensor_occupied_greyscale.svg'
import { ReactComponent as StatusCheck } from '../../../images/icons/tasks/safety_check_greyscale.svg'
import { ReactComponent as GeneralUpdate } from '../../../images/icons/tasks/description_greyscale.svg'
import { ReactComponent as PottedPlan } from '../../../images/icons/tasks/potted_plant_greyscale.svg'
import { ReactComponent as QueryStats } from '../../../images/icons/tasks/query_stats_greyscale.svg'
import { ReactComponent as ModelTraining } from '../../../images/icons/tasks/model_training_greyscale.svg'


export interface TaskIconData {
    type: string
  }
  
const TaskIcon = (props: TaskIconData) => {
    const width = 24
    const height = 24
    
    if(props.type === 'create_plan')
      return (
        <AssignmentAdd viewBox='0, 0, 48, 48' width={width} height={height} />
      )

    if(props.type === 'preventure_review_mc_data')
      return (
          <QueryStats viewBox='0, 0, 48, 48' width={width} height={height} />
      )

    if(props.type === 'peerwell_assign_program')
      return (
        <Peerwell width={width} height={height} />
      )

    if(props.type === 'peerwell_follow_up')
      return (
        <PottedPlan viewBox='0, 0, 48, 48' width={width} height={height} />
      )

    if(props.type === 'preventure_assign_training')
      return (
        <ModelTraining viewBox='0, 0, 48, 48' width={width} height={height} />
      )

    if(props.type === 'preventure_training_follow_up')
      return (   
        <PottedPlan viewBox='0, 0, 48, 48' width={width} height={height}  />
      )

    if(props.type === 'status_check')
      return (
        <StatusCheck viewBox='0, 0, 48, 48' width={width} height={height} />
      )

    if(props.type === 'general_update')
      return (
        <GeneralUpdate viewBox='0, 0, 48, 48' width={width} height={height} />
      )

    if(props.type === 'sensor_reminder')
      return (
        <SensorOccupied viewBox='0, 0, 48, 48' width={width} height={height} />
      )
    
    return (
        <></>
    )
}


export default TaskIcon