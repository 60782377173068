import React, { useState, useEffect } from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Toolbar,
  IconButton,
  Popover,
  Grid,
  Button,
  TablePagination,
  TableSortLabel,
} from '@mui/material'
import {
  CompanyData,
  FilterOptions,
  NewFilterOptions,
  StringOps,
  NumberOps,
  DateOps,
  StringFilter,
  NumberFilter,
  DateFilter,
  Order,
} from './companies-types'
import FilterListIcon from '@mui/icons-material/FilterList'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { columns } from './companies-table-definitions'
import { TYPE_MAPPINGS, STRING_OPS, NUMBER_OPS, DATE_OPS, OPERATORS } from './companies-constants'
import CompaniesFilter from './companies-filter'
import CompaniesNewFilter from './companies-new-filter'
import Avatar from '../shared/avatar'

type CompaniesTableProps = {
  companies: CompanyData[]
}

const CompaniesTable = ({ companies }: CompaniesTableProps) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof CompanyData>('companyName')
  const [page, setPage] = useState<number>(0)
  const [filterAnchorElement, setFilterAnchorElement] = useState<HTMLButtonElement | null>(null)
  const [filterList, setFilterList] = useState<Record<string, FilterOptions>>()
  const [filteredCompanies, setFilteredCompanies] = useState<CompanyData[] | undefined>(companies)
  const [newFilter, setNewFilter] = useState<NewFilterOptions>()

  const hideNewLogicalOps = !filterList || Object.keys(filterList).length === 0
  const allowAddNewFilter =
    !newFilter || !!(newFilter && newFilter.columnName && filterList && filterList[newFilter.columnName])
  const newFilterColumns = columns.filter((col) => !filterList || (filterList && !filterList[col.id]))

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setFilterAnchorElement(null)
  }

  const filterValue = (filterOptions: FilterOptions, columnValue: string | number | undefined) => {
    if (columnValue === undefined || columnValue === '' || filterOptions.value === undefined) return false
    if (filterOptions.filterType.columnType === 'string') {
      const value = columnValue as string
      switch (filterOptions.filterType.operator as StringOps) {
        case 'eq':
          return value?.trim()?.toLowerCase() === filterOptions.value?.trim()?.toLowerCase()
        case 'contains':
          return value?.trim()?.toLowerCase().includes(filterOptions.value?.trim()?.toLowerCase())
        default:
          return false
      }
    } else if (filterOptions.filterType.columnType === 'number') {
      const value = columnValue as number
      switch (filterOptions.filterType.operator as NumberOps) {
        case 'eq':
          return value === Number(filterOptions.value)
        case 'gte':
          return value >= Number(filterOptions.value)
        case 'lte':
          return value <= Number(filterOptions.value)
        case 'gt':
          return value > Number(filterOptions.value)
        case 'lt':
          return value < Number(filterOptions.value)
        default:
          return false
      }
    } else {
      const value = new Date(columnValue as string).toDateString()
      switch (filterOptions.filterType.operator as DateOps) {
        case 'io':
          return value === filterOptions.value
        case 'ioa':
          return value >= filterOptions.value
        case 'iob':
          return value <= filterOptions.value
        case 'ia':
          return value > filterOptions.value
        case 'ib':
          return value < filterOptions.value
        default:
          return false
      }
    }
  }

  useEffect(() => {
    setPage(0)
    if (filterList && companies) {
      let newCompaniesList = companies
      Object.entries(filterList).forEach(([column, filterOptions]) => {
        if (
          filterOptions.value === undefined ||
          filterOptions.value === null ||
          filterOptions.value.trim().length === 0
        )
          return
        newCompaniesList = newCompaniesList.filter((company: CompanyData) => {
          const columnValue = company[column as keyof CompanyData]
          return filterValue(filterOptions, columnValue)
        })
        return
      })
      setFilteredCompanies(newCompaniesList)
    } else {
      setFilteredCompanies(companies)
    }
  }, [filterList, companies])

  const handleRequestSort = (property: keyof CompanyData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const compareBySortDirection = (a: string | number, b: string | number): number => {
    //a (and by extension b) are strings, orderBy is the same key for both.
    if (isNaN(+a)) {
      if (b === '' && a !== '') return -1
      if (b !== '' && a === '') return 1
      return b.toLocaleString().localeCompare(a.toLocaleString())
    } else {
      if (b < a) return -1
      if (b > a) return 1
      return 0
    }
  }

  const removeFilter = (column: string) => {
    if (filterList) {
      if (Object.keys(filterList).length === 1) {
        setFilterList(undefined)
        setFilteredCompanies(companies)
        setPage(0)
      } else {
        const newFilterList = { ...filterList }
        delete newFilterList[column]
        setFilterList({ ...newFilterList })
        setPage(0)
      }
    }
  }

  const handleColumnChange = (prevColumn: string, newColumn: string) => {
    if (!newColumn || !filterList) return
    else {
      const filter = { ...filterList[prevColumn] }
      const newFilterList = { ...filterList }
      delete newFilterList[prevColumn]
      if (filter.filterType.columnType === TYPE_MAPPINGS[newColumn.toLowerCase()]) {
        newFilterList[newColumn] = filter
      } else {
        //Because the column type changed, we have to reset the value.
        filter.value = ''
        switch (TYPE_MAPPINGS[newColumn.toLowerCase()]) {
          case 'string':
            filter.filterType = { columnType: 'string', operator: STRING_OPS[0].value } as StringFilter
            break
          case 'number':
            filter.filterType = { columnType: 'number', operator: NUMBER_OPS[0].value } as NumberFilter
            break
          case 'date':
            filter.filterType = { columnType: 'date', operator: DATE_OPS[0].value } as DateFilter
            break
          default:
            filter.filterType = { columnType: 'string', operator: STRING_OPS[0].value } as StringFilter
        }
      }
      setFilterList({ ...newFilterList })
    }
  }
  const handleOpChange = (column: string, newOp: string) => {
    if (!newOp || !filterList) return
    else {
      const filter = { ...filterList[column] }
      switch (TYPE_MAPPINGS[column.toLowerCase()]) {
        case 'string':
          filter.filterType.operator = newOp as StringOps
          break
        case 'number':
          filter.filterType.operator = newOp as NumberOps
          break
        case 'date':
          filter.filterType.operator = newOp as DateOps
          break
        default:
          filter.filterType.operator = newOp as StringOps
      }
      const newFilterList = { ...filterList }
      newFilterList[column] = { ...filter }
      setFilterList({ ...newFilterList })
    }
  }

  const handleValueChange = (column: string, newValue: string) => {
    if (!filterList) return
    else {
      const filter = { ...filterList[column] }
      filter.value = newValue ?? ''
      const newFilterList = { ...filterList }
      newFilterList[column] = { ...filter }
      setFilterList({ ...newFilterList })
    }
  }

  const addNewFilter = () => {
    if (!newFilter) {
      setNewFilter({})
      setPage(0)
    }
  }

  const removeNewFilter = () => {
    setNewFilter(undefined)
  }

  const handleNewColumnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const columnName = event.target.value
    if (!columnName) return
    else {
      const filter = { ...newFilter }
      filter.columnName = columnName
      switch (TYPE_MAPPINGS[columnName.toLowerCase()]) {
        case 'string':
          filter.filterType = { columnType: 'string' } as StringFilter
          break
        case 'number':
          filter.filterType = { columnType: 'number' } as NumberFilter
          break
        case 'date':
          filter.filterType = { columnType: 'date' } as DateFilter
          break
        default:
          filter.filterType = { columnType: 'string' } as StringFilter
      }
      updateNewFilter(filter)
    }
  }

  const handleNewOpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOp = event.target.value
    if (!newOp || !newFilter || !newFilter?.filterType || !newFilter.filterType?.columnType) return
    else {
      const filter = { ...newFilter }
      switch (newFilter.filterType.columnType) {
        case 'string':
          filter.filterType = { ...filter.filterType, operator: newOp } as StringFilter
          break
        case 'number':
          filter.filterType = { ...filter.filterType, operator: newOp } as NumberFilter
          break
        case 'date':
          filter.filterType = { ...filter.filterType, operator: newOp } as DateFilter
          break
        default:
          filter.filterType = { ...filter.filterType, operator: newOp } as StringFilter
      }
      updateNewFilter(filter)
    }
  }

  const updateNewFilter = (filter: NewFilterOptions) => {
    if (filter.filterType && filter.columnName && filter.filterType.operator) {
      const newFilterList = { ...filterList }
      newFilterList[filter.columnName] = {
        filterType: filter.filterType,
      }
      setFilterList({ ...newFilterList })
      setNewFilter(undefined)
      setPage(0)
    } else {
      setNewFilter({ ...filter })
      setPage(0)
    }
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: '10px' }}>
      <Box pl={2} pt={2}>
        <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>Companies</Typography>
      </Box>
      <Toolbar variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleFilterClick}
          data-testid="filter-icon"
        >
          <FilterListIcon />
        </IconButton>
        {filterList ? (
          <Box display="flex" alignItems={'center'}>
            <Typography fontSize="14px">Filtered to:</Typography>
            {Object.entries(filterList)
              .filter(([_, filterOption]) => filterOption.value !== undefined && filterOption.value.trim().length > 0)
              .map(([column, filterOptions], index) => {
                const columnName = columns.find((col) => col.id === column)?.label
                const filterType = OPERATORS[filterOptions.filterType.columnType].find(
                  (op) => op.value === filterOptions.filterType.operator,
                )?.title
                return (
                  <>
                    <Box mx={1} display="flex">
                      <Typography fontWeight={500} mr={'4px'}>
                        {columnName}
                      </Typography>{' '}
                      {filterType}
                      <Typography fontWeight={500} ml={'4px'}>
                        {filterOptions.value}
                      </Typography>
                    </Box>
                    <Typography>
                      {index + 1 !==
                      Object.entries(filterList).filter(
                        ([_, filterOption]) => filterOption.value !== undefined && filterOption.value.trim().length > 0,
                      ).length
                        ? '|'
                        : null}
                    </Typography>
                  </>
                )
              })}
          </Box>
        ) : null}
      </Toolbar>
      <Popover
        open={!!filterAnchorElement}
        anchorEl={filterAnchorElement}
        sx={{ zIndex: '100' }}
        onClose={handleClose}
        data-testid="filter-popover"
      >
        <Box sx={{ p: 2, width: '715px', minHeight: '100px' }}>
          <Grid container rowSpacing={3}>
            {filterList
              ? Object.entries(filterList).map(([column, filterOptions], index) => {
                  const filteredColumns = columns.filter(
                    (col) => column === col.id || (filterList && !filterList[col.id]),
                  )
                  return (
                    <CompaniesFilter
                      index={index}
                      column={column}
                      filteredColumns={filteredColumns}
                      filterOptions={filterOptions}
                      removeFilter={removeFilter}
                      handleColumnChange={handleColumnChange}
                      handleOpChange={handleOpChange}
                      handleValueChange={handleValueChange}
                    />
                  )
                })
              : null}
            <CompaniesNewFilter
              newFilter={newFilter}
              removeNewFilter={removeNewFilter}
              handleNewColumnChange={handleNewColumnChange}
              handleNewOpChange={handleNewOpChange}
              hideLogicalOps={hideNewLogicalOps}
              columns={newFilterColumns}
            />
            <Grid item>
              <Button onClick={addNewFilter} variant="text" disabled={!allowAddNewFilter} startIcon={<AddIcon />}>
                Add filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '12px',
                    }}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCompanies
              ?.sort((a: CompanyData, b: CompanyData) => {
                return order === 'desc'
                  ? compareBySortDirection(a[orderBy] ?? '', b[orderBy] ?? '')
                  : -compareBySortDirection(a[orderBy] ?? '', b[orderBy] ?? '')
              })
              .slice(page * 10, (page + 1) * 10)
              .map((company: CompanyData, index: number) => (
                <TableRow key={`${company.companyId}-${index}`}>
                  <TableCell>
                    <Link to={`/companies/${company?.companyId}`}>{company?.companyName}</Link>
                  </TableCell>
                  <TableCell>
                    {company?.coachName && (
                      <IconButton onClick={() => {}} sx={{ p: 0 }}>
                        <Avatar altText={company?.coachName} displayText={company?.coachName} role="Coach" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{!company?.companyTier ? '' : company?.companyTier}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent={'flex-end'} alignItems="center">
        <Typography fontSize="14px">Rows per page: 10</Typography>
        <TablePagination
          component={'div'}
          rowsPerPageOptions={[10]}
          count={companies?.length}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </Paper>
  )
}
export default CompaniesTable
