export type Channel = {
  channelArn: string
  lastMessageTimestamp?: Date
  readMarkerTimestamp?: Date
  name: string
  displayName: string
  unreadMessageCount: number
  previewMessage?: string
  participants: User[]
  messages: ChannelMessage[]
  nextMessagePageToken?: string
}

export type ChannelMessage = {
  messageId: string
  content: string
  createdTimestamp: Date
  sender: User
  status: string
}

export type User = {
  userid: string
  name: string
  role?: Role
  company?: string
}

export type ChatUser = {
  companyId: string
  companyName: string
  roles: string[]
  fullname: string
  userid: string
}

export type AutocompleteUser = {
  label: string
  id: string
  role: string
  company: string
}

export type LatestReadMessage = {
  channelArn: string
  latestReadMessageTimestamp: Date
}

export type Role = 'Coach' | 'Worker' | 'Safety Pro' | 'Dev Admin' | 'Customer Success'

export const ROLE_MAPPINGS: Record<string, string> = {
  devadmin: 'Dev Admin',
  worker: 'Worker',
  coach: 'Coach',
  supervisor: 'Safety Pro',
  customer_success: 'Customer Success',
}

export const SHORTHAND_ROLE_MAPPINGS: Record<string, string> = {
  devadmin: 'Admin',
  worker: 'W',
  coach: 'Coach',
  supervisor: 'SO',
  customer_success: 'CS',
}
