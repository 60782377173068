import { Box } from '@mui/material'
import { ReactComponent as QueryStats } from '../../../images/icons/query_stats.svg'
import React from 'react'

const QueryStatsIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#8E24AA',
        width: '67px',
        height: '69px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        marginLeft: '1rem',
        position: 'absolute',
        marginTop: '-1rem',
      }}
    >
      <QueryStats />
    </Box>
  )
}

export default QueryStatsIcon
