
export const isOverdue = (dueDate: Date) => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    dueDate.setHours(0, 0, 0, 0)
    return dueDate < currentDate
}

export const isToday = (dueDate: Date) => {
    const currentDate = new Date()
    return isDateEqual(dueDate, currentDate)
}

export const isDateEqual = (date1: Date, date2: Date) => {
    date1.setHours(0, 0, 0, 0)
    date2.setHours(0, 0, 0, 0)
    return date1.toDateString() === date2.toDateString()
}