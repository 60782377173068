import React, { useContext, useEffect } from 'react'
import Login from './components/login'
import { LoadingContext, LoadingState } from './contexts/loading-context'
import { AuthenticationContext } from './contexts/authentication-context'
import InternalServer from './errors/internal-server'
import RenderRoute from './routes'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'
import { RouteContext } from './contexts/route-context'

export interface AppProps {
  isAuthenticated: boolean
  loading: LoadingState
  defaultParams: string
}

const App = ({ isAuthenticated, loading, defaultParams }: AppProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { defaultRoute, setInitialLoad } = useContext(RouteContext)
  const { setLoading } = useContext(LoadingContext)

  useEffect(() => {
    //We want to redirect people to the /workers page with the default filter if they are first logging in (loading state of LoginRoute) and the current pathname
    //is "/", "/workers", or "/login".
    if (
      isAuthenticated &&
      loading === LoadingState.LoginRoute &&
      (location.pathname === '/workers' || location.pathname === '/' || location.pathname === '/login')
    ) {
      const filter = defaultParams ? `?filter=${defaultParams}` : ''
      setLoading(LoadingState.Ready)
      setInitialLoad(true)
      navigate(
        { pathname: defaultRoute, search: filter },
        { state: { prevPath: location.pathname, prevSearch: location.search } },
      )
    } else if (isAuthenticated && loading === LoadingState.LoginRoute) {
      setLoading(LoadingState.Ready)
    }
  }, [isAuthenticated, location.pathname, navigate, defaultRoute, defaultParams, loading])

  console.log(`[[App isAuthenticated: ${isAuthenticated} LoadingState: ${LoadingState[loading]}]]`)

  if ((!isAuthenticated && loading !== LoadingState.Error) || loading === LoadingState.Unauthorized) return <Login />

  if (loading === LoadingState.Error) return <InternalServer loginRedirect={true} />

  if (loading !== LoadingState.Ready)
    return (
      <Box sx={{ marginLeft: '50vw', marginTop: '50vh' }}>
        <CircularProgress />
      </Box>
    )

  return <RenderRoute />
}

const MemoizedApp = React.memo(App, (prevProps, nextProps) => {
  return (
    prevProps.isAuthenticated === nextProps.isAuthenticated &&
    prevProps.loading === nextProps.loading &&
    prevProps.defaultParams === nextProps.defaultParams
  )
})

export default MemoizedApp
