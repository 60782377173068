import { Grid, IconButton, Typography, TextField, MenuItem } from '@mui/material'
import { OPERATORS } from './companies-constants'
import { Column, columns } from './companies-table-definitions'
import CloseIcon from '@mui/icons-material/Close'
import { FilterOptions } from './companies-types'

export interface CompaniesFilterProps {
  index: number
  column: string
  filteredColumns: Column[]
  filterOptions: FilterOptions
  removeFilter: (column: string) => void
  handleColumnChange: (currentColumn: string, newColumn: string) => void
  handleOpChange: (currentColumn: string, newOp: string) => void
  handleValueChange: (currentColumn: string, newValue: string) => void
}
const CompaniesFilter = (props: CompaniesFilterProps) => {
  const {
    index,
    column,
    filteredColumns,
    filterOptions,
    removeFilter,
    handleColumnChange,
    handleOpChange,
    handleValueChange,
  } = props
  return (
    <Grid container item spacing={1} key={`grid-${index}`}>
      <Grid container item xs={1}>
        <IconButton aria-label="remove filter" onClick={() => removeFilter(column)}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid container item xs={1} alignItems="center">
        <Typography id="logical-operator" sx={{ visibility: index === 0 ? 'hidden' : 'visible' }}>
          And
        </Typography>
      </Grid>
      <Grid container item xs={3}>
        <TextField
          id="column"
          value={columns.find((col: any) => col.id === column)?.id}
          onChange={(event) => handleColumnChange(column, event.target.value)}
          label="Column"
          select
          size="small"
          sx={{ minWidth: '100%' }}
        >
          {filteredColumns.map((col) => {
            return (
              <MenuItem value={col.id} key={col.id}>
                {col.label}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid container item xs={3}>
        <TextField
          id="operation"
          value={filterOptions.filterType.operator}
          label="Operator"
          select
          size="small"
          sx={{ minWidth: '100%' }}
          onChange={(event) => handleOpChange(column, event.target.value)}
        >
          {OPERATORS[filterOptions.filterType.columnType].map((op) => {
            return (
              <MenuItem value={op.value} key={op.value}>
                {op.title}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
      <Grid container item xs={4}>
        <TextField
          value={filterOptions.value ?? ''}
          size="small"
          label="Value"
          sx={{ minWidth: '100%' }}
          onChange={(event) => handleValueChange(column, event.target.value)}
        />
      </Grid>
    </Grid>
  )
}

export default CompaniesFilter
