import React from 'react'
import { AuthContextState } from './authentication-context-provider'

export interface UserInfo {
  username: string
  firstname: string
  lastname: string
  email: string
  admin: boolean
  userId: string
  roles: UserRole[]
}

export type UserRole = 'coach' | 'devadmin' | 'customer_success'

export interface AuthContext {
  userInfo?: UserInfo
  isAuthenticated: boolean
  jwt?: string
  setAuthenticationState: React.Dispatch<Partial<AuthContextState>>
}

export const AuthenticationContext = React.createContext<AuthContext>({
  isAuthenticated: false,
  setAuthenticationState: () => {},
})
