import { Box, Typography } from '@mui/material'
import { WorkerDetailData } from '../workers/workers-types'
import { Link } from 'react-router-dom'
import WorkerProfile from './worker-profile'

export interface ProfileStripProps {
  worker?: WorkerDetailData
}

const ProfileStrip = (props: ProfileStripProps) => {
  const { worker } = props

  const handleClick = () => {
    console.log('handle click')
  }

  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={3} width="1000px">
      <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{worker?.name}</Typography>
      <Box display={'flex'}>
        <Typography sx={{ fontSize: '14px', mr: '4px' }}>Tier:</Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{!worker?.tier ? '--' : worker?.tier}</Typography>
      </Box>
      <Box display={'flex'}>
        <Typography sx={{ fontSize: '14px', mr: '4px' }} noWrap>
          Job:
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 500, maxWidth: '275px' }} noWrap>
          {worker?.occupation}
        </Typography>
      </Box>
      <Typography sx={{ fontSize: '14px' }}>
        Company: <Link to={`/companies/${worker?.companyId}`}>{worker?.company}</Link>
      </Typography>
      <Typography sx={{ fontSize: '14px', visibility:'hidden' }}>
        <WorkerProfile />
      </Typography>
    </Box>
  )
}

export default ProfileStrip
