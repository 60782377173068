import { Box, Typography, AppBar, IconButton, Menu, MenuItem, Button, Tooltip, Grid, Badge } from '@mui/material'
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext, AuthenticationContext } from '../../contexts/authentication-context'
import Avatar from './avatar'
import { ChimeContext } from '../../contexts/chime-context'
import { LoadingContext, LoadingState } from '../../contexts/loading-context'
import AppWhiteIcon from './icons/app-icon-white'
import { RouteContext } from '../../contexts/route-context'

const CustomLayout = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0)

  const navigate = useNavigate()
  const location = useLocation()
  const { userInfo } = useContext<AuthContext>(AuthenticationContext)
  const { defaultParams, defaultRoute, setInitialLoad, clearDefaultFilter } = useContext(RouteContext)
  const name = `${userInfo?.firstname} ${userInfo?.lastname}`
  const { channels, resetChime } = useContext(ChimeContext)
  const { setAuthenticationState } = useContext(AuthenticationContext)
  const { loading, setLoading } = useContext(LoadingContext)

  const logout = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/logout`, {
        method: 'GET',
        credentials: 'include',
      })

      if (response.ok) {
        clearDefaultFilter()
        resetChime()
        setAuthenticationState({
          userInfo: undefined,
          isAuthenticated: false,
          jwt: undefined,
        })
        navigate('/login', { state: { prevPath: location.pathname, prevSearch: location.search } })
        console.log(`logout: [[${LoadingState[loading]} -->` + ' Ready]], deleted cookies, removed auth state')
        setLoading(LoadingState.Ready)
      } else {
        console.error(`Logout failed with status ${response.status}`)
      }
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }, [navigate, resetChime])

  const menuItems =
    userInfo?.admin || userInfo?.roles.includes('coach')
      ? [
          { title: 'Workers', primaryPath: defaultRoute, secondaryPath: '/' },
          { title: 'Messages', primaryPath: '/messages' },
          { title: 'Companies', primaryPath: '/companies' },
          // { title: 'Overview', primaryPath: '/overview' },
        ]
      : [
          { title: 'Workers', primaryPath: defaultRoute, secondaryPath: '/' },
          { title: 'Companies', primaryPath: '/companies' },
        ]
  const settings = [
    // { title: 'Profile', path: '/' },
    { title: 'Logout', path: '/logout', onClick: logout },
  ]

  //if (userInfo?.admin) menuItems.push({ title: 'Admin', primaryPath: '/admin' })
  // menuItems.push({ title: 'Admin', primaryPath: '/admin' })

  const handleNavigation = (path: string) => {
    if (path === defaultRoute || path === '/') {
      setInitialLoad(true)
      navigate(
        { pathname: defaultRoute, search: `${defaultParams ? `filter=${defaultParams}` : ''}` },
        { state: { prevPath: location.pathname, prevSearch: location.search } },
      )
    } else {
      navigate(path, { state: { prevPath: location.pathname, prevSearch: location.search } })
    }
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  useEffect(() => {
    const newUnreadMessageCount = channels
      .map((channel) => channel.unreadMessageCount)
      .reduce((acc, cur) => acc + cur, 0)
    if (newUnreadMessageCount !== unreadMessageCount) setUnreadMessageCount(newUnreadMessageCount)
  }, [channels])

  return (
    <Box sx={{ height: '100vh' }}>
      <AppBar position="static" sx={{ borderRadius: '0px', pb: 0 }}>
        <Grid
          container
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '1425px',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            xs={1.5}
            sx={{
              marginLeft: '-8px',
              alignItems: 'center',
            }}
          >
            <AppWhiteIcon />
          </Grid>
          <Grid display="flex" item xs={8}>
            {menuItems.map((item) => (
              <Box
                sx={{
                  ...((item.primaryPath === location.pathname || item.secondaryPath === location.pathname) && {
                    borderBottom: '5px solid #C1DCF3',
                  }),
                  ...(item.primaryPath !== location.pathname &&
                    item.secondaryPath !== location.pathname && {
                      mb: '5px',
                    }),
                  width: '120px',
                  justifyContent: 'center',
                  display: 'flex',
                  '&:not(:last-child)': {
                    mr: '1rem',
                  },
                  '&:not(:first-of-type)': {
                    ml: '1rem',
                  },
                }}
                key={`${item.title}-box`}
              >
                {item.title === 'Messages' ? (
                  <Button
                    key={`${item.title}-button`}
                    onClick={() => handleNavigation(item.primaryPath)}
                    sx={{
                      px: '16px',
                      minHeight: '60px',
                      borderRadius: '0',
                      alignItems: 'end',
                      color: 'white',
                      textTransform: 'none',
                      fontSize: '16px',
                    }}
                  >
                    <Badge
                      badgeContent={
                        channels.some((channel) => channel.unreadMessageCount === 20)
                          ? `${unreadMessageCount}+`
                          : unreadMessageCount
                      }
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: '#D32F2F',
                          color: '#FFFFFF',
                          fontSize: '14px',
                          visibility: unreadMessageCount > 0 ? 'visible' : 'hidden',
                          borderRadius: channels.some((channel) => channel.unreadMessageCount >= 20) ? '6px' : '10px',
                          transform: channels.some((channel) => channel.unreadMessageCount >= 20)
                            ? 'translate(35px, -5px) scale(1.1)'
                            : 'translate(20px, -5px) scale(1.1)',
                          padding: channels.some((channel) => channel.unreadMessageCount >= 20) ? '0 3px' : '0px',
                        },
                      }}
                    >
                      {item.title}
                    </Badge>
                  </Button>
                ) : (
                  <Button
                    key={`${item.title}-button`}
                    onClick={() => handleNavigation(item.primaryPath)}
                    sx={{
                      px: '16px',
                      minHeight: '60px',
                      borderRadius: '0',
                      alignItems: 'end',
                      color: 'white',
                      textTransform: 'none',
                      fontSize: '16px',
                    }}
                  >
                    {item.title}
                  </Button>
                )}
              </Box>
            ))}
          </Grid>
          <Grid item display="flex" justifyContent={'flex-end'} alignItems={'flex-end'} xs={2}>
            <Typography mr={2}>Hi, {userInfo?.firstname}</Typography>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  displayText={`${userInfo?.firstname ?? ''} ${userInfo?.lastname ?? ''}`}
                  altText={name}
                  customStyle={{ border: 'none' }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.title} onClick={setting.onClick}>
                  <Typography textAlign="center">{setting.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </AppBar>
      <Box
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '0rem 0rem 3rem 0rem',
          maxWidth: '1425px',
          alignItems: 'center',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default CustomLayout
