import { Backdrop, Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { columns } from './coach-tasks-definitions'
import { useContext, useEffect, useState } from 'react'
import {
  CoachTaskRecord,
  Activity,
  Status,
  UpdateCoachTaskResponse,
  NewCoachTaskResponse,
} from '../worker-details-types'
import CoachTask from './coach-task'
import CoachNewTask from './coach-new-task'
import { AuthContext, AuthenticationContext } from '../../../contexts/authentication-context'

interface WorkerPlanProps {
  workerTier?: number
  workerid: string
  onMcsTasksToReviewChange: (decrement: boolean) => void
}

const WorkerPlan = (props: WorkerPlanProps) => {
  const [coachTasks, setCoachTasks] = useState<Record<string, CoachTaskRecord>>()
  const [showNewCoachForm, setShowNewCoachForm] = useState<boolean>()
  const [editedTask, setEditedTask] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const { workerTier, workerid, onMcsTasksToReviewChange } = props
  const { userInfo } = useContext<AuthContext>(AuthenticationContext)

  useEffect(() => {
    const abortController = new AbortController()
    if (userInfo?.username && workerid) {
      getCoachTasks(abortController, userInfo.username, workerid)
    }
    return () => {
      abortController.abort()
    }
  }, [userInfo?.username, workerid])

  const getCoachTasks = (abortController: AbortController, username: string, worker: string) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/coach/${username}/tasks/${worker}`, {
      method: 'GET',
      signal: abortController.signal,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error(`An error occurred when calling the /coach/${username}/tasks/${worker} endpoint.`)
      })
      .then((taskData: Record<string, CoachTaskRecord>) => {
        setCoachTasks({ ...taskData })
        setLoading(false)
      })
      .catch((error) => {
        //TODO: Figure out what to do with errors
        setLoading(false)
      })
  }

  const toggleShowNewCoach = (showNewTask: boolean) => {
    setShowNewCoachForm(showNewTask)
    if (showNewTask) setEditedTask(undefined)
  }

  const handleEditTaskUpdate = (taskId: string | undefined) => {
    setEditedTask(taskId)
    if (taskId) setShowNewCoachForm(false)
  }

  const updateCoachTasks = (updatedTask: UpdateCoachTaskResponse) => {
    const updatedCoachTasks = coachTasks as Record<string, CoachTaskRecord>
    const updatedCoachTask = updatedCoachTasks[updatedTask.taskId]
    updatedCoachTasks[updatedTask.taskId] = {
      ...updatedTask.task,
      notes: updatedTask.note ? [updatedTask.note, ...updatedCoachTask.notes] : updatedCoachTask.notes,
    }
  }

  const addCoachTask = (newTask: NewCoachTaskResponse) => {
    const newCoachTasks = coachTasks as Record<string, CoachTaskRecord>
    newCoachTasks[newTask.taskId] = {
      workerId: workerid,
      dueDate: newTask.dueDate,
      activity: newTask.activity as Activity,
      status: newTask.status as Status,
      notes: newTask.notes,
    }
  }

  const handleLoadingChange = (isLoading: boolean) => {
    setLoading(isLoading)
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper sx={{ height: '550px', overflowY: 'auto' }}>
        <Box display="flex" justifyContent={'space-between'} alignItems={'center'} mb={3}>
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>Plan</Typography>
          <Button variant="text" sx={{ textTransform: 'none' }} onClick={() => toggleShowNewCoach(true)}>
            + Add Task
          </Button>
        </Box>
        <Grid container spacing={2} pl={0}>
          <Grid container item xs={12}>
            {columns.map((column) => (
              <Grid item key={column.id} xs={column.size} borderBottom={'1px solid rgb(224, 224, 224)'}>
                <Typography
                  sx={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontWeight: '500',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                  }}
                >
                  {column.label}
                </Typography>
              </Grid>
            ))}
            <Grid item xs={1} borderBottom={'1px solid rgb(224, 224, 224)'}></Grid>
          </Grid>
          {showNewCoachForm ? (
            <Grid container item xs={12} borderBottom={'1px solid rgb(224, 224, 224)'}>
              <CoachNewTask
                addCoachTask={addCoachTask}
                toggleShowNewCoach={toggleShowNewCoach}
                setLoading={handleLoadingChange}
                workerId={workerid}
                workerTier={workerTier}
              />
            </Grid>
          ) : null}
          {coachTasks
            ? Object.entries(coachTasks)
                .sort((a: [string, CoachTaskRecord], b: [string, CoachTaskRecord]) => {
                  //Ignored should be at the bottom of the list sorted by dueDate
                  if (a[1].status === 'Ignored' && b[1].status !== 'Ignored') return 1
                  else if (b[1].status === 'Ignored' && a[1].status !== 'Ignored') return -1
                  //Completed should be above ignored at the bottom of the list sorted by dueDate
                  else if (a[1].status === 'Completed' && b[1].status !== 'Completed') return 1
                  else if (b[1].status === 'Completed' && a[1].status !== 'Completed') return -1
                  //All other tasks should just be sorted by dueDate
                  else if (a[1].dueDate == b[1].dueDate) return 0
                  return a[1].dueDate > b[1].dueDate ? 1 : -1
                })
                .map(([taskId, task]) => {
                  return (
                    <CoachTask
                      key={taskId}
                      taskId={taskId}
                      task={task as CoachTaskRecord}
                      allowEdit={taskId === editedTask}
                      setEditedTaskId={handleEditTaskUpdate}
                      setLoading={handleLoadingChange}
                      updateCoachTasks={updateCoachTasks}
                      onMcsTasksToReviewChange={onMcsTasksToReviewChange}
                    />
                  )
                })
            : null}
        </Grid>
      </Paper>
    </>
  )
}

export default WorkerPlan
