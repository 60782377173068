import { Box, Grid, Paper, Typography } from '@mui/material'
import { WorkerDetailData } from '../../workers/workers-types'
import MCRiskLevel from './mc-risk-level'
import MCSetHistoryPanel from './mcset-history-panel'

export interface MotionCoachData {
  worker: WorkerDetailData
}

const MotionCoach = (props: MotionCoachData) => {
  const { worker } = props

  const handleClick = () => {
    console.log('handle click')
  }

  return (
    <Paper sx={{ pt: '19px', height: '550px', overflow: 'scroll' }}>
      <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
        <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>Movement Coach</Typography>
      </Box>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <MCRiskLevel worker={worker} />
        </Grid>
        <Grid item>
          <MCSetHistoryPanel worker={worker} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default MotionCoach
