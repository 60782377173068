import React from 'react'
import './index.css'
import reportWebVitals from './reportWebVitals'
import AuthenticationContextProvider from './contexts/authentication-context-provider'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { CssBaseline, GlobalStyles } from '@mui/material'
import ReactDOM from 'react-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import ChimeContextProvider from './contexts/chime-context-provider'
import LoadingContextProvider from './contexts/loading-context-provider'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import AppWrapper from './appWrapper'
import RouteContextProvider from './contexts/route-context-provider'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoadingContextProvider>
        <AuthenticationContextProvider>
          <ChimeContextProvider>
            <RouteContextProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles
                  styles={{
                    body: { backgroundColor: '#F2F8FC' },
                    a: { textDecoration: 'none', color: '#0B78D0' }, //Normally this would be in the theme provider, but we also have react router links.
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <AppWrapper />
                </LocalizationProvider>
              </ThemeProvider>
            </RouteContextProvider>
          </ChimeContextProvider>
        </AuthenticationContextProvider>
      </LoadingContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
