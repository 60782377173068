import { LinearProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { linearProgressClasses } from '@mui/material/LinearProgress'

const CustomLinearProgress = styled(LinearProgress)(({ datatype }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#EDEDED',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      datatype === 'very low'
        ? '#4CAF50'
        : datatype === 'low'
        ? '#A5D6A7'
        : datatype === 'moderate'
        ? '#FDD835'
        : datatype === 'high'
        ? '#EF9A9A'
        : '#D32F2F',
  },
}))

export interface ScoreLinearProgressData {
  value: number
}

const ScoreLinearProgress = (props: ScoreLinearProgressData) => {
  const { value } = props

  const type = value < 2 ? 'very low' : value < 4 ? 'low' : value < 6 ? 'moderate' : value < 8 ? 'high' : 'very high'

  return <CustomLinearProgress datatype={type} variant="determinate" value={value * 10} />
}

export default ScoreLinearProgress
