import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import {
  ChimeSDKMessagingClient,
  SendChannelMessageCommand,
  UpdateChannelReadMarkerCommand,
} from '@aws-sdk/client-chime-sdk-messaging'
import { AuthenticationContext } from './authentication-context'
import {
  ChatAPIChannel,
  ChatCredentials,
  ChimeChannelDetailsEvent,
  ChimeContext,
  ChimeMessageEvent,
  IChimeContext,
  UpdatedChannel,
} from './chime-context'
import { LoadingContext, LoadingState } from './loading-context'
import {
  ConsoleLogger,
  DefaultMessagingSession,
  LogLevel,
  Message,
  MessagingSessionConfiguration,
  MessagingSessionObserver,
  PrefetchOn,
  PrefetchSortBy,
} from 'amazon-chime-sdk-js'
import { AutocompleteUser, Channel, LatestReadMessage, User } from '../components/messages/message-types'
import { compareAsc, compareDesc } from 'date-fns'

interface ChimeProviderProps {
  children: ReactNode
}
type NewMessage = {
  channelArn: string
  message: string
}

const ChimeContextProvider = (props: ChimeProviderProps) => {
  const { loading, setLoading } = useContext(LoadingContext)
  const { jwt, userInfo } = useContext(AuthenticationContext)
  const [sessionClosed, setSessionClosed] = useState<boolean>(false)
  const [updatedChannel, setUpdatedChannel] = useState<UpdatedChannel>()
  const [channels, setChannels] = useState<Channel[]>([])
  const [chatCredentials, setChatCredentials] = useState<ChatCredentials>()
  const [messagingClient, setMessagingClient] = useState<ChimeSDKMessagingClient>()
  const [messagingSession, setMessagingSession] = useState<DefaultMessagingSession>()
  const [newChannelMessageToSend, setNewChannelMessageToSend] = useState<NewMessage>()
  const [mostRecentReadMessage, setMostRecentReadMessage] = useState<LatestReadMessage>()

  const { children } = props

  const resetChime = () => {
    setMessagingClient(undefined)
    setMessagingSession(undefined)
    setChatCredentials(undefined)
    setChannels([])
  }

  const updateUnreadNotifications = async (channelArn: string) => {
    if (messagingClient) {
      const command = new UpdateChannelReadMarkerCommand({
        ChannelArn: channelArn,
        ChimeBearer: chatCredentials?.userArn,
      })
      await messagingClient.send(command)
      setChannels([
        ...channels.map((channel) => {
          if (channel.channelArn === channelArn) {
            return { ...channel, unreadMessageCount: 0, readMarkerTimestamp: new Date() }
          }
          return channel
        }),
      ])
    }
  }

  const getOrCreateChannel = async (members: AutocompleteUser[], message: string) => {
    if (members.length) {
      const payload = {
        clientRequestToken: window.crypto.randomUUID(),
        members: [userInfo?.userId, ...members.map((member) => member.id)],
      }
      try {
        const fetchResponse = await fetch(`${process.env.REACT_APP_CHAT_API_BASE_URL}/chat`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${jwt}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
        if (fetchResponse.ok) {
          const body = await fetchResponse.json()
          const existingChannel = channels.find((channel) => channel.channelArn === body.channelArn)
          console.log(channels)
          if (!existingChannel) {
            const participants = members.map((member) => {
              return {
                userid: member.id,
                name: member.label,
                company: member.company,
                role: member.role,
              } as User
            })
            const displayName =
              participants.length > 1
                ? participants
                    .map((participant, _index) => {
                      const nameParts = participant.userid.split(' ')
                      const lastName = nameParts?.[1] ? `${nameParts[1][0]}.` : ''
                      return `${nameParts[0]} ${lastName}`
                    })
                    .join(', ')
                : participants.filter((participant) => participant.userid !== userInfo?.userId)[0].name

            setChannels([
              {
                channelArn: body.channelArn,
                name: 'new-channel',
                lastMessageTimestamp: new Date(),
                readMarkerTimestamp: new Date(),
                unreadMessageCount: 0,
                displayName: displayName,
                participants: participants,
                previewMessage: message,
                messages: [],
              },
              ...channels,
            ])
          }
          setNewChannelMessageToSend({ channelArn: body.channelArn, message: message })
          return body.channelArn as string
        } else if (fetchResponse.status === 422) {
          const body = await fetchResponse.json()
          console.log(`The user(s): ${body.detail.join(',')} did not exist`)
        } else throw Error('An unknown error occurred.')
      } catch (error) {
        console.log(error)
      }
    }
    return null
  }

  const values: IChimeContext | null = useMemo(() => {
    return {
      messagingClient,
      messagingSession,
      channels,
      chatCredentials,
      mostRecentReadMessage,
      getOrCreateChannel,
      updateUnreadNotifications,
      resetChime,
    }
  }, [messagingClient, messagingSession, channels, chatCredentials, mostRecentReadMessage])

  const primaryObserver = {
    messagingSessionDidStart: () => {
      console.log('Session started')
      if (sessionClosed) setSessionClosed(false)
    },
    messagingSessionDidStartConnecting: (reconnecting: any) => {
      if (reconnecting) {
        console.log('Start reconnecting')
      } else {
        console.log('Start connecting')
      }
    },
    messagingSessionDidStop: (event: any) => {
      if (!sessionClosed) setSessionClosed(true)
      console.log(`Closed: ${event.code} ${event.reason}`)
    },
    messagingSessionDidReceiveMessage: (message: Message) => {
      console.log(message.type)
      if (message.type === 'CHANNEL_DETAILS') {
        handleUpdateChannel(message)
      }
    },
  }

  const getChimeCredentials = () => {
    const payload = { clientRequestToken: window.crypto.randomUUID() }
    fetch(`${process.env.REACT_APP_CHAT_API_BASE_URL}/credentials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) return response.json()
        else if (response.status === 401) {
          throw Error('JWT is invalid or expired.')
        } else {
          throw Error('An unknown error occurred.')
        }
      })
      .then((data) => {
        setChatCredentials({
          userArn: data.userArn,
          sessionToken: data.sessionToken,
          accessKeyId: data.accessKeyId,
          secretAccessKey: data.secretAccessKey,
        })
        setMessagingClient(
          new ChimeSDKMessagingClient({
            region: 'us-east-1',
            credentials: {
              sessionToken: data.sessionToken,
              secretAccessKey: data.secretAccessKey,
              accessKeyId: data.accessKeyId,
            },
          }),
        )
        console.log(`getChimeCredentials: [[${LoadingState[loading]} -->` + ' GetUserChannels]]')
        setLoading(LoadingState.GetUserChannels)
      })
      .catch((err) => {
        //TODO: determine what to do with errors here.
        setLoading(LoadingState.Error)
      })
  }

  const handleUpdateChannel = (message: Message) => {
    const channelDetails: ChimeChannelDetailsEvent = JSON.parse(message.payload)
    const channel: UpdatedChannel = {
      channelArn: channelDetails.Channel.ChannelArn,
      unreadMessageCount: channelDetails.ChannelMessages.filter((channelMessage) => {
        const unread = compareAsc(
          new Date(channelMessage.CreatedTimestamp),
          channelDetails.ReadMarkerTimestamp ? new Date(channelDetails.ReadMarkerTimestamp) : new Date(0),
        )
        return unread === 1 && channelMessage.Sender.Arn !== chatCredentials?.userArn
      }).length,
      lastMessageTimestamp: channelDetails.ChannelMessages.length
        ? new Date(channelDetails.ChannelMessages[0].CreatedTimestamp)
        : undefined,
      previewMessage: channelDetails.ChannelMessages.length ? channelDetails.ChannelMessages[0].Content : undefined,
      readMarkerTimestamp: new Date(
        channelDetails.ReadMarkerTimestamp?.length > 0 ? channelDetails.ReadMarkerTimestamp : 0,
      ),
    }
    setUpdatedChannel(channel)
  }

  const getChimeMessageSession = () => {
    if (chatCredentials) {
      const logger = new ConsoleLogger('SDK', LogLevel.DEBUG)
      const configuration = new MessagingSessionConfiguration(
        chatCredentials.userArn,
        window.crypto.randomUUID(),
        undefined,
        messagingClient,
      )
      configuration.prefetchOn = PrefetchOn.Connect
      configuration.prefetchSortBy = PrefetchSortBy.LastMessageTimestamp
      const newSession = new DefaultMessagingSession(configuration, logger)
      newSession.addObserver(primaryObserver)
      newSession.start()
      setMessagingSession(newSession)
      console.log(`getChimeMessageSession: [[${LoadingState[loading]} -->` + ' Ready]]')
      setLoading(LoadingState.SetDefaultParams)
    }
  }

  const handleNewMessage = (messageEvent: Message) => {
    const messageDetails: ChimeMessageEvent = JSON.parse(messageEvent.payload)

    const newChannels = channels.map((channel) => {
      if (channel.channelArn === messageDetails.ChannelArn) {
        const userArnParts = messageDetails.Sender.Arn.split('/')
        const channelMessages = channel.messages
        channelMessages.unshift({
          messageId: messageDetails.MessageId,
          content: messageDetails.Content,
          createdTimestamp: new Date(messageDetails.CreatedTimestamp),
          sender: { userid: userArnParts[userArnParts.length - 1], name: messageDetails.Sender.Name },
          status: messageDetails.Status.Value,
        })
        return {
          ...channel,
          messages: [...channelMessages],
          previewMessage: messageDetails.Content,
          lastMessageTimestamp: new Date(messageDetails.CreatedTimestamp),
          unreadMessageCount:
            chatCredentials?.userArn !== messageDetails.Sender.Arn
              ? (channel.unreadMessageCount += 1)
              : channel.unreadMessageCount,
        }
      }
      return channel
    })
    return newChannels
  }

  const getUserChannels = (abortController: AbortController) => {
    fetch(`${process.env.REACT_APP_CHAT_API_BASE_URL}/user/${userInfo?.userId}/channels`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      signal: abortController.signal,
    })
      .then((response) => {
        if (response.ok) return response.json()
        else {
          throw Error('An unknown error occurred.')
        }
      })
      .then((channelResponse) => {
        setChannels(
          channelResponse.channels.map((channel: ChatAPIChannel): Channel => {
            const participants = channel.members.map((member) => {
              return {
                userid: member.userid,
                name: member.fullname,
                company: member.companyName,
                role: member.roles[0],
              } as User
            })

            const displayName =
              participants.length > 2
                ? participants
                    .filter((member) => member.userid !== userInfo?.userId)
                    .map((participant, _index) => {
                      const nameParts = participant.name.split(' ')
                      const lastName = nameParts?.[1] ? `${nameParts[1][0]}.` : ''
                      return `${nameParts[0]} ${lastName}`
                    })
                    .join(', ')
                : participants.filter((member) => member.userid !== userInfo?.userId)[0].name

            return {
              channelArn: channel.channelArn,
              name: channel.channelName,
              lastMessageTimestamp: channel.lastMessageTimestamp ? new Date(channel.lastMessageTimestamp) : undefined,
              readMarkerTimestamp: channel.readMarkerTimestamp ? new Date(channel.readMarkerTimestamp) : undefined,
              unreadMessageCount: 0,
              displayName: displayName,
              participants: participants,
              messages: [],
            }
          }),
        )
        console.log(`getUserChannels: [[${LoadingState[loading]} -->` + ' CreateMessageSession]]')
        setLoading(LoadingState.CreateMessageSession)
      })
      .catch((err) => {
        setLoading(LoadingState.Error)
      })
  }

  useEffect(() => {
    if (newChannelMessageToSend && messagingClient) {
      const command = new SendChannelMessageCommand({
        ChannelArn: newChannelMessageToSend.channelArn,
        ChimeBearer: chatCredentials?.userArn,
        Content: newChannelMessageToSend.message,
        Type: 'STANDARD',
        Persistence: 'PERSISTENT',
      })
      messagingClient.send(command)
    }
  }, [newChannelMessageToSend, messagingClient])

  useEffect(() => {
    if (!messagingClient && loading === LoadingState.LoadingChatCredentials) {
      getChimeCredentials()
    }
  }, [messagingClient, loading])

  useEffect(() => {
    const abortController = new AbortController()
    if (loading === LoadingState.GetUserChannels) getUserChannels(abortController)

    return () => {
      abortController.abort()
    }
  }, [loading])

  useEffect(() => {
    if (!messagingSession && loading === LoadingState.CreateMessageSession) {
      getChimeMessageSession()
    }
    return () => {
      messagingSession?.removeObserver(primaryObserver)
    }
  }, [messagingSession, loading])

  useEffect(() => {
    if (!messagingSession) return

    let observer: MessagingSessionObserver
    observer = {
      messagingSessionDidReceiveMessage: (messageEvent: Message) => {
        if (messageEvent.type === 'CREATE_CHANNEL_MESSAGE') {
          const newChannels = handleNewMessage(messageEvent)
          setChannels([...newChannels])
          setNewChannelMessageToSend(undefined)
        }
      },
    }
    messagingSession.addObserver(observer)

    return () => {
      messagingSession?.removeObserver(observer)
    }
  }, [messagingSession, channels])

  useEffect(() => {
    //Unfortunately, because the observer dispatch is called asynchronously multiple state updates happen at the same time
    //if we try to update channels in-place with setChannels([...channels, newChannel]). This useEffect seemed to be the only alternative.
    if (updatedChannel) {
      const latestChannel = channels.find((channel) => channel.channelArn === updatedChannel.channelArn)
      if (latestChannel && latestChannel.readMarkerTimestamp && latestChannel.lastMessageTimestamp) {
        const newLatestTimestamp = mostRecentReadMessage?.latestReadMessageTimestamp
          ? compareAsc(latestChannel.readMarkerTimestamp, mostRecentReadMessage.latestReadMessageTimestamp) === 1
          : true
        if (newLatestTimestamp) {
          let latest = latestChannel.lastMessageTimestamp
          //If the read marker timestamp is greater than the last message timestamp, then use the last message timestamp.  Otherwise use the readmarker timestamp
          if (compareAsc(latestChannel.readMarkerTimestamp, latestChannel.lastMessageTimestamp) <= 0)
            latest = latestChannel.readMarkerTimestamp

          const updatedMostRecentReadMessage = {
            channelArn: latestChannel.channelArn,
            latestReadMessageTimestamp: latest,
          }
          setMostRecentReadMessage(updatedMostRecentReadMessage)
        }
      }

      setChannels([
        ...channels.map((channel) => {
          if (channel.channelArn === updatedChannel.channelArn) {
            return {
              ...channel,
              lastMessageTimestamp: updatedChannel.lastMessageTimestamp,
              readMarkerTimestamp: updatedChannel.readMarkerTimestamp,
              previewMessage: updatedChannel.previewMessage,
              unreadMessageCount: updatedChannel.unreadMessageCount,
            }
          }
          return channel
        }),
      ])
    }
  }, [updatedChannel])

  return <ChimeContext.Provider value={values}>{children}</ChimeContext.Provider>
}

export default ChimeContextProvider
