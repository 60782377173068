import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { LoadingContext, LoadingState } from './loading-context'
import { IRouteContext, RouteContext } from './route-context'
import { AuthenticationContext } from './authentication-context'
import { format } from 'date-fns'

interface RouteProviderProps {
  children: ReactNode
}

const RouteContextProvider = (props: RouteProviderProps) => {
  const [defaultRoute, setDefaultRoute] = useState<string>('/workers')
  const [defaultParams, setDefaultParams] = useState<string>('')
  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const { loading, setLoading } = useContext(LoadingContext)

  const { userInfo } = useContext(AuthenticationContext)

  const { children } = props

  const clearDefaultFilter = () => {
    window.sessionStorage.clear()
    setDefaultParams('')
  }

  const getInitialDefault = () => {
    return userInfo && !userInfo.admin && userInfo.roles.some((role) => role === 'coach')
      ? `riskLevel~in~Moderate, High, Very High~plus~coach~eq~${userInfo?.firstname} ${
          userInfo?.lastname
        }~plus~dueDate~iob~${format(new Date(), 'M/dd/yy')}`
      : ''
  }

  const resetDefaultFilter = () => {
    const defaultFilter = getInitialDefault()
    window.sessionStorage.setItem('defaultFilter', defaultFilter)
    setDefaultParams(defaultFilter)
    return defaultFilter
  }

  const values: IRouteContext = useMemo(() => {
    return {
      defaultRoute,
      defaultParams,
      initialLoad,
      setDefaultParams: (newDefaultParams: string) => {
        setDefaultParams(newDefaultParams)
      },
      resetDefaultFilter: resetDefaultFilter,
      clearDefaultFilter: clearDefaultFilter,
      setInitialLoad: (isInitialLoad: boolean = false) => {
        setInitialLoad(isInitialLoad)
      },
    }
  }, [defaultRoute, defaultParams, initialLoad])

  useEffect(() => {
    if (loading === LoadingState.SetDefaultParams) {
      const storedFilter = window.sessionStorage.getItem('defaultFilter')
      console.log(`setDefaultParams: [[${LoadingState[loading]} -->` + ' LoginRoute]]')
      const defaultFilter = getInitialDefault()
      setDefaultParams(storedFilter ? storedFilter : defaultFilter)
      setLoading(LoadingState.LoginRoute)
    }
  }, [loading])

  useEffect(() => {
    if (loading === LoadingState.Ready) {
      const storedFilter = window.sessionStorage.getItem('defaultFilter')
      if (storedFilter !== defaultParams) {
        window.sessionStorage.setItem('defaultFilter', defaultParams)
      }
    }
  }, [defaultParams, loading])

  return <RouteContext.Provider value={values}>{children}</RouteContext.Provider>
}

export default RouteContextProvider
