import { Box } from '@mui/material'
import { ReactComponent as PrevLogo } from '../../../images/icons/Bardavon-Prevention-Logo-White.svg'

const AppWhiteIcon = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#1976d2',
        width: '140px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '0',
        position: 'absolute',
        marginTop: '-1rem',
      }}
    >
      <PrevLogo />
    </Box>
  )
}

export default AppWhiteIcon
