import { Box, Button, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { ChimeContext } from '../../contexts/chime-context'
import {
  ListChannelMessagesCommand,
  ListChannelMessagesCommandInput,
  SendChannelMessageCommandOutput,
} from '@aws-sdk/client-chime-sdk-messaging'
import { compareAsc, format } from 'date-fns'
import { Channel, SHORTHAND_ROLE_MAPPINGS, Role } from './message-types'
import ReactMarkdown from 'react-markdown'
import isUrl from 'validator/lib/isURL'
import Avatar from '../shared/avatar'
import { AuthenticationContext } from '../../contexts/authentication-context'

interface ChatWindowProps {
  currentChannel: Channel
  updateCurrentChannel: (updatedChannel?: Channel) => void
  message: string
  setMessage: (newMessage: string) => void
  sendMessage: () => Promise<SendChannelMessageCommandOutput | undefined>
}

const ChatWindow = (props: ChatWindowProps) => {
  const [dateGroupings, setDateGroupings] = useState<Date[]>([])

  const { messagingClient, chatCredentials, updateUnreadNotifications } = useContext(ChimeContext)
  const { userInfo } = useContext(AuthenticationContext)

  const { currentChannel, updateCurrentChannel, message, setMessage, sendMessage } = props

  const scrollPreviousTopRef = useRef<null | HTMLDivElement>(null)
  const scrollBottomRef = useRef<null | HTMLDivElement>(null)

  const recipientsInput = useMemo(() => {
    return currentChannel.participants.length > 2
      ? currentChannel.participants
          .filter((member) => member.userid !== userInfo?.userId)
          .map((participant, _index) => {
            const nameParts = participant.name.split(' ')
            const lastName = nameParts?.[1] ? `${nameParts[1][0]}.` : ''
            return `${nameParts[0]} ${lastName} (${participant.role ?? ''}) - ${participant.company ?? ''}`
          })
          .join(', ')
      : currentChannel.participants.filter((member) => member.userid !== userInfo?.userId)[0].name
  }, [currentChannel.participants])

  const recipientsText = useMemo(() => {
    const lastIndex =
      currentChannel.participants.length > 2
        ? currentChannel.participants.length - 1
        : currentChannel.participants.length - 2
    return currentChannel.participants.length > 2
      ? currentChannel.participants.map((participant, _index) => {
          const nameParts = participant.name.split(' ')
          const lastName = nameParts?.[1] ? `${nameParts[1][0]}.` : ''

          return _index === lastIndex ? (
            <Fragment key={_index}>
              <Typography fontWeight={500} mr={'4px'} fontSize={'14px'}>{`${nameParts[0]} ${lastName} (${
                SHORTHAND_ROLE_MAPPINGS[participant.role ?? '']
              })`}</Typography>
              <Typography fontSize={'14px'}>{`- ${participant.company ?? ''}`}</Typography>
            </Fragment>
          ) : (
            <Fragment key={_index}>
              <Typography fontWeight={500} mr={'4px'} fontSize={'14px'}>{`${nameParts[0]} ${lastName} (${
                SHORTHAND_ROLE_MAPPINGS[participant.role ?? '']
              })`}</Typography>
              <Typography mr={'4px'} fontSize={'14px'}>{`- ${participant.company ?? ''},`}</Typography>
            </Fragment>
          )
        })
      : currentChannel.participants.filter((member) => member.userid !== userInfo?.userId)[0].name
  }, [currentChannel.participants])

  const loadHistoricalMessages = async () => {
    if (messagingClient && chatCredentials && currentChannel && currentChannel.nextMessagePageToken) {
      const commandPayload: ListChannelMessagesCommandInput = {
        ChannelArn: currentChannel.channelArn,
        ChimeBearer: chatCredentials.userArn,
        NextToken: currentChannel.nextMessagePageToken,
      }
      const messageListResponse = await messagingClient.send(new ListChannelMessagesCommand(commandPayload))
      const messages =
        messageListResponse?.ChannelMessages?.map((chimeMessage) => {
          const userArnParts = (chimeMessage.Sender?.Arn as string).split('/')
          return {
            messageId: chimeMessage.MessageId as string,
            createdTimestamp: chimeMessage.CreatedTimestamp as Date,
            content: chimeMessage.Content ?? '',
            sender: {
              userid: userArnParts[userArnParts.length - 1],
              name: chimeMessage.Sender?.Name as string,
            },
            status: chimeMessage?.Status?.Value as string,
          }
        }) ?? []

      updateCurrentChannel({
        ...currentChannel,
        messages: [...messages, ...currentChannel.messages].sort((a, b) =>
          compareAsc(a.createdTimestamp, b.createdTimestamp),
        ),
        nextMessagePageToken: messageListResponse.NextToken,
      })
      scrollPreviousTopRef.current?.scrollIntoView({ behavior: 'auto' })
    }
  }

  const addMarkdownURL = (content: string) => {
    const newContent = content
      .split(' ')
      .map((segment) => {
        if (isUrl(segment)) {
          const puncRegex = /((?![/])\p{P})$/gu
          if (!segment.startsWith('http')) return `[${segment}](https://${segment.replace(puncRegex, '')})`
          return `[${segment}](${segment.replace(puncRegex, '')})`
        }
        return segment
      })
      .join(' ')
    return newContent
  }

  const LinkRenderer = (linkProps: any) => {
    return (
      <a href={linkProps.href} target="_blank" rel="noreferrer">
        {linkProps.children}
      </a>
    )
  }

  useEffect(() => {
    if (currentChannel) {
      setDateGroupings(
        [
          ...new Set(
            currentChannel?.messages?.map((channelMessage) => {
              const timestamp = new Date(channelMessage.createdTimestamp.getTime())
              timestamp.setHours(0, 0, 0, 0)
              return timestamp.getTime()
            }) ?? [],
          ),
        ].map((date) => new Date(date)),
      )
    }
  }, [currentChannel])

  useEffect(() => {
    if (!currentChannel.readMarkerTimestamp) {
      updateUnreadNotifications(currentChannel.channelArn)
    } else if (
      currentChannel.lastMessageTimestamp &&
      compareAsc(currentChannel.lastMessageTimestamp, currentChannel.readMarkerTimestamp) === 1
    ) {
      updateUnreadNotifications(currentChannel.channelArn)
    }
  }, [currentChannel])

  useEffect(() => {
    scrollBottomRef.current?.scrollIntoView({ behavior: 'auto' })
  }, [])

  const messages = useMemo(() => {
    if (!currentChannel.messages || currentChannel.messages.length === 0) return
    return dateGroupings
      .sort((a, b) => compareAsc(a, b))
      .map((date: Date) => {
        const messageGroup = currentChannel.messages.filter((channelMessage) => {
          const messageDate = new Date(channelMessage.createdTimestamp.getTime())
          messageDate.setHours(0, 0, 0, 0)
          return compareAsc(messageDate, date) === 0
        })
        const currentDate = new Date()
        currentDate.setHours(0, 0, 0, 0)
        const dateIsToday = compareAsc(date, currentDate) === 0

        //Because of how we're sorting, the last message before "Load more" will always be the first element
        const lastMessageId = currentChannel.messages[0].messageId

        return (
          <Fragment key={date.getTime()}>
            <Divider sx={{ px: 3 }}>{dateIsToday ? 'Today' : format(date, 'MMMM d')}</Divider>
            {messageGroup
              ?.sort((a, b) => compareAsc(a.createdTimestamp, b.createdTimestamp))
              ?.map((channelMessage) => {
                const senderName =
                  channelMessage.sender.userid !== userInfo?.userId && currentChannel.participants.length > 2 ? (
                    <Typography fontSize="14px" fontWeight={500} pl={3} pt={0.5}>
                      {channelMessage.sender.name}
                    </Typography>
                  ) : null
                return (
                  <Box justifyContent={'space-between'} key={channelMessage.messageId}>
                    {lastMessageId === channelMessage.messageId ? <Box ref={scrollPreviousTopRef} /> : null}
                    {senderName}
                    <Box
                      pt={senderName ? 0 : 2}
                      pb={0.5}
                      px={3}
                      pr={6}
                      display="flex"
                      justifyContent={channelMessage.sender.userid === userInfo?.userId ? 'flex-end' : 'space-between'}
                    >
                      <Box
                        pl={1}
                        pr={2}
                        sx={{
                          borderRadius: '3px',
                          backgroundColor: channelMessage.sender.userid === userInfo?.userId ? '#6FA136' : '#F5F5F5',
                          color: channelMessage.sender.userid === userInfo?.userId ? '#FFFFFF' : '#000000',
                          mr: 2,
                          maxWidth: '800px',
                          fontSize: '14px',
                          '& a': {
                            textDecoration: 'underline',
                            color: channelMessage.sender.userid === userInfo?.userId ? '#FFFFFF' : '#0B78D0',
                          },
                        }}
                      >
                        <ReactMarkdown components={{ a: LinkRenderer }}>
                          {addMarkdownURL(channelMessage.content)}
                        </ReactMarkdown>
                      </Box>
                      <Box sx={{ alignSelf: 'center' }}>
                        {!dateIsToday ? (
                          <Typography fontSize="14px">
                            {channelMessage.createdTimestamp.toLocaleDateString('en-US', {
                              dateStyle: 'short',
                            })}
                          </Typography>
                        ) : null}
                        <Typography fontSize="14px">
                          {channelMessage.createdTimestamp.toLocaleTimeString('en-US', { timeStyle: 'short' })}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )
              })}
          </Fragment>
        )
      })
  }, [dateGroupings])

  return (
    <>
      <Box
        sx={{
          borderBottom: '1px solid #979797',
          p: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <>
          <Avatar
            displayText={
              currentChannel.participants.length > 2 ? currentChannel.participants.length.toString() : recipientsInput
            }
            altText={
              currentChannel.participants.length > 2 ? currentChannel.participants.length.toString() : recipientsInput
            }
            customStyle={{ mr: 2 }}
            role={
              currentChannel.participants.length === 2
                ? (currentChannel.participants.filter((paarticipant) => paarticipant.userid !== userInfo?.userId).at(0)
                    ?.role as Role)
                : undefined
            }
          />
          <Box width="100%" display="flex" justifyContent={'flex-start'} alignItems={'center'}>
            {recipientsText}
          </Box>
        </>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          mb: '76px',
        }}
      >
        {currentChannel.nextMessagePageToken ? (
          <Button variant="text" onClick={() => loadHistoricalMessages()}>
            Load more
          </Button>
        ) : null}
        {messages}
        <Box ref={scrollBottomRef} />
      </Box>
      <Box
        sx={{
          pl: '25px',
          pr: '100px',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          background: '#FFFFFF',
        }}
      >
        <TextField
          fullWidth
          multiline
          maxRows={10}
          id="message"
          label="Message"
          name="message"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
          inputProps={{
            maxLength: 1500,
          }}
          sx={{
            '& .MuiFormHelperText-root': {
              color: '#000000',
              position: 'absolute',
              top: '-12px',
              right: '-5px',
              background: 'white',
              zIndex: 1,
            },
          }}
          helperText={`${message?.length ?? 0}/1500`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="send message"
                  edge="end"
                  sx={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: '#0B78D0',
                    mr: 0.5,
                    zIndex: 2,
                    '&:hover': {
                      backgroundColor: '#0B78D0',
                    },
                  }}
                  onClick={() =>
                    sendMessage().then((response) => {
                      if (response) setMessage('')
                    })
                  }
                >
                  <ArrowUpwardIcon sx={{ color: 'white' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  )
}

export default ChatWindow
