import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import { WorkerDetailData } from '../../workers/workers-types'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ArrowRightOutlined } from '@mui/icons-material'
import MCAverageRiskScore from './mc-average-risk-score'

export interface MCRiskLevelData {
  worker: WorkerDetailData
}

const MCRiskLevel = (props: MCRiskLevelData) => {
  const { worker } = props

  const handleClick = () => {
    console.log('handle click')
  }

  const hasWorkerTrend = worker?.trend != null && worker?.trend?.overallTrend != null

  return (
    <Grid container direction="column" spacing={0} sx={{ marginTop: '0px' }}>
      <Grid item container>
        <Grid item>
          <Typography sx={{ fontSize: '12px', fontWeight: 300 }}>Risk level:</Typography>
        </Grid>
        <Grid item xs={hasWorkerTrend ? 3 : 6}>
          <Typography sx={{ fontSize: '12px', fontWeight: 500, paddingLeft: '2px' }}>
            {hasWorkerTrend ? worker.riskLevel : 'Not Enough Data'}
          </Typography>
        </Grid>
        <Grid item xs={hasWorkerTrend ? 3 : 0}>
          {hasWorkerTrend ? (
            worker.trend!.overallTrend! > 0 ? (
              <Box display="flex" data-testid="triangle-icon-up">
                <ArrowDropUpIcon sx={{ color: '#D32F2F', transform: 'scale(1.5)', pb: '3px' }} />
                <Typography sx={{ fontSize: '12px', fontWeight: 300 }}>
                  {Math.abs(Math.round(worker.trend!.overallTrend!))}%
                </Typography>
              </Box>
            ) : worker.trend!.overallTrend! < 0 ? (
              <Box display="flex" data-testid="triangle-icon-down">
                <ArrowDropDownIcon sx={{ color: '#4CAF50', transform: 'scale(1.5)', pb: '3px' }} />
                <Typography sx={{ fontSize: '12px', fontWeight: 300 }}>
                  {Math.abs(Math.round(worker.trend!.overallTrend!))}%
                </Typography>
              </Box>
            ) : (
              <Box display="flex" data-testid="triangle-icon-down">
                <ArrowRightOutlined sx={{ color: '#000000', transform: 'scale(1.5)', pb: '3px' }} />
                <Typography sx={{ fontSize: '12px', fontWeight: 300 }}>
                  {Math.abs(Math.round(worker.trend!.overallTrend!))}%
                </Typography>
              </Box>
            )
          ) : null}
        </Grid>
      </Grid>
      <Grid item>
        <MCAverageRiskScore trend={worker?.trend} />
      </Grid>
    </Grid>
  )
}

export default MCRiskLevel
