import { Grid, Typography } from '@mui/material'
import { MotionCoachSet, WorkerDetailData } from '../../workers/workers-types'
import MCSetView from './mcset-view'

export interface MCSetHistoryPanelData {
  worker: WorkerDetailData
}

const MCSetHistoryPanel = (props: MCSetHistoryPanelData) => {
  const { worker } = props

  const handleClick = () => {
    console.log('handle click')
  }

  return (
    <Grid container direction="column" spacing={2}>
      {worker?.motionCoachSets?.map((item: MotionCoachSet) => (
        <MCSetView key={item.movementCoachSetId} mcs={item} />
      ))}
    </Grid>
  )
}
export default MCSetHistoryPanel
