import {
  Badge,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Avatar from '../shared/avatar'
import { useContext, useEffect, useState } from 'react'
import { ChimeContext } from '../../contexts/chime-context'
import { compareAsc, compareDesc, set } from 'date-fns'
import { Channel, ROLE_MAPPINGS, Role } from './message-types'
import { AuthenticationContext } from '../../contexts/authentication-context'
import SearchIcon from '@mui/icons-material/Search'

interface MessageInboxProps {
  setSelectedChannelArn: (channelArn?: string) => void
  selectedChannelArn?: string
  setAutofocus: (newAutofocus: boolean) => void
}

const MessageInbox = (props: MessageInboxProps) => {
  const { setSelectedChannelArn, selectedChannelArn, setAutofocus } = props
  const { channels } = useContext(ChimeContext)
  const { userInfo } = useContext(AuthenticationContext)

  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0)
  const [filteredChannels, setFilteredChannels] = useState<Channel[]>(channels)

  useEffect(() => {
    const newUnreadMessageCount = channels
      .map((channel) => channel.unreadMessageCount)
      .reduce((acc, cur) => acc + cur, 0)

    const filteredUnreadMessageCount = filteredChannels
      .map((channel) => channel.unreadMessageCount)
      .reduce((acc, cur) => acc + cur, 0)

    if (newUnreadMessageCount !== unreadMessageCount) setUnreadMessageCount(newUnreadMessageCount)

    const newFilteredChannels = channels.filter((channel) =>
      filteredChannels.some((filteredChannel) => filteredChannel.channelArn === channel.channelArn),
    )
    setFilteredChannels([...newFilteredChannels])
  }, [channels])

  const handleSearchChange = (searchValue: string) => {
    if (selectedChannelArn) {
      setAutofocus(false)
      setSelectedChannelArn(undefined)
    }
    if (searchValue.length > 1) {
      //We want to build out a heirarchy of search results where name matches come before company matches which come before role matches.
      //Duplicates need to be removed further down the heirarchy otherwise their position in the list will be altered (i.e. we can't indiscriminately eliminate dupes)
      const nameMatches = channels.filter((channel) =>
        channel.participants.some(
          (participant) =>
            participant.name.toLowerCase().includes(searchValue.toLowerCase()) &&
            participant.userid !== userInfo?.userId,
        ),
      )
      const companyMatches = channels.filter(
        (channel) =>
          channel.participants.some(
            (participant) =>
              participant.company &&
              participant.company.toLowerCase().includes(searchValue.toLowerCase()) &&
              participant.userid !== userInfo?.userId,
          ) && !nameMatches.some((nameMatch) => channel.channelArn === nameMatch.channelArn),
      )
      const roleMatches = channels.filter(
        (channel) =>
          (channel.participants.some(
            (participant) =>
              participant.role &&
              participant.role.toLowerCase().includes(searchValue.toLowerCase()) &&
              participant.userid !== userInfo?.userId,
          ) ||
            channel.participants.some(
              (participant) =>
                participant.role &&
                ROLE_MAPPINGS[participant.role].toLowerCase().includes(searchValue.toLowerCase()) &&
                participant.userid !== userInfo?.userId,
            )) &&
          !nameMatches.some((nameMatch) => channel.channelArn === nameMatch.channelArn) &&
          !companyMatches.some((companyMatch) => channel.channelArn === companyMatch.channelArn),
      )
      setFilteredChannels([...nameMatches, ...companyMatches, ...roleMatches])
    } else if (searchValue.length === 0) {
      setFilteredChannels([...channels])
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <Typography fontSize={'18px'}>Inbox</Typography>
        <IconButton
          onClick={() => {
            setAutofocus(true)
            setSelectedChannelArn()
          }}
        >
          <AddCircleOutlineIcon sx={{ color: '#0B78D0' }} />
        </IconButton>
      </Box>
      <Box px={2} pb={2}>
        <TextField
          fullWidth
          id="search"
          label="Search"
          name="search"
          autoFocus
          size="small"
          onChange={(event) => handleSearchChange(event.target.value ?? '')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ overflow: 'scroll' }} pt={1}>
        <Badge
          badgeContent={
            filteredChannels.some((channel) => channel.unreadMessageCount === 20)
              ? `${unreadMessageCount}+`
              : unreadMessageCount
          }
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#D32F2F',
              color: '#FFFFFF',
              transform: channels.some((channel) => channel.unreadMessageCount >= 20)
                ? 'translate(35px, -4px) scale(1.1)'
                : 'translate(25px, -4px) scale(1.1)',
              fontSize: '14px',
              visibility: unreadMessageCount > 0 ? 'visible' : 'hidden',
              borderRadius: channels.some((channel) => channel.unreadMessageCount >= 20) ? '6px' : '10px',
              padding: channels.some((channel) => channel.unreadMessageCount >= 20) ? '0 3px' : '0px',
            },
          }}
        >
          <Typography color="#7F7F7F" fontSize="14px" pl={2}>
            MESSAGES
          </Typography>
        </Badge>
        {!filteredChannels.length ? (
          <Typography color="#7F7F7F" fontSize="16px" px={2} pt={3}>
            No results found
          </Typography>
        ) : (
          filteredChannels
            .sort((a, b) => {
              if (!a.lastMessageTimestamp && b.lastMessageTimestamp) return 1
              else if (a.lastMessageTimestamp && !b.lastMessageTimestamp) return -1
              return compareDesc(a.lastMessageTimestamp as Date, b.lastMessageTimestamp as Date)
            })
            .map((channel) => {
              const currentDate = new Date()
              currentDate.setHours(0, 0, 0, 0)
              const newTimestamp = channel?.lastMessageTimestamp
                ? new Date(channel?.lastMessageTimestamp.getTime())
                : new Date()
              newTimestamp.setHours(0, 0, 0, 0)
              const dateIsToday = compareAsc(newTimestamp, currentDate) === 0
              const otherParticipants = channel.participants.filter(
                (participant) => participant.userid !== userInfo?.userId,
              )
              const channelName =
                otherParticipants.length > 1
                  ? channel.participants
                      .map((participant) => {
                        const nameParts = participant.name.split(' ')
                        const lastName = nameParts?.[1] ? `${nameParts[1][0]}.` : ''
                        return `${nameParts[0]} ${lastName}`
                      })
                      .join(', ')
                  : otherParticipants[0].name
              return (
                <Grid
                  container
                  sx={{
                    width: '100%',
                    py: 2,
                    pl: 2,
                    pr: 1,
                    cursor: 'pointer',
                    backgroundColor: selectedChannelArn === channel.channelArn ? '#F5F5F5' : 'white',
                  }}
                  key={channel.channelArn}
                  onClick={() => setSelectedChannelArn(channel.channelArn)}
                >
                  <Grid item xs={2}>
                    <Badge
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: '#D32F2F',
                          color: '#D32F2F',
                          boxShadow: `0 0 0 2px`,
                          '&::after': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            animation: 'ripple 1.2s infinite ease-in-out',
                            border: '1px solid currentColor',
                            content: '""',
                          },
                        },
                        '@keyframes ripple': {
                          '0%': {
                            transform: 'scale(.8)',
                            opacity: 1,
                          },
                          '100%': {
                            transform: 'scale(2.4)',
                            opacity: 0,
                          },
                        },
                      }}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      variant="dot"
                      invisible={channel.unreadMessageCount === 0}
                    >
                      <Avatar
                        altText={channelName}
                        displayText={
                          otherParticipants.length > 1 ? channel.participants.length.toString() : channelName
                        }
                        role={
                          otherParticipants.length > 1
                            ? undefined
                            : (ROLE_MAPPINGS[otherParticipants[0].role ?? ''] as Role)
                        }
                      />
                    </Badge>
                  </Grid>
                  <Grid item xs={7.75}>
                    {otherParticipants.length === 1 ? (
                      <>
                        <Typography fontSize={'14px'} fontWeight={500} noWrap>{`${channelName} (${
                          ROLE_MAPPINGS[otherParticipants[0].role ?? '']
                        })`}</Typography>
                        <Typography fontSize={'12px'}>{`${otherParticipants[0].company ?? ''}`}</Typography>
                      </>
                    ) : (
                      <>
                        <Typography fontSize={'14px'} fontWeight={500} noWrap>{`${channelName}`}</Typography>
                        <Typography fontSize={'12px'} noWrap>{`${otherParticipants
                          .filter((participant) => participant.company)
                          .map((participant) => participant.company)
                          .join(', ')}`}</Typography>
                      </>
                    )}
                    <Typography fontSize={'14px'} fontWeight={channel.unreadMessageCount > 0 ? 500 : 400} noWrap>
                      {channel.previewMessage}
                    </Typography>
                  </Grid>
                  <Grid item xs={2.25}>
                    {channel?.lastMessageTimestamp ? (
                      !dateIsToday ? (
                        <Typography fontSize="12px" color={'#7F7F7F'} textAlign={'right'}>
                          {channel.lastMessageTimestamp.toLocaleDateString('en-US', {
                            dateStyle: 'short',
                          })}
                        </Typography>
                      ) : (
                        <Typography fontSize={'12px'} color={'#7F7F7F'} textAlign={'right'}>
                          {channel.lastMessageTimestamp.toLocaleTimeString('en-US', { timeStyle: 'short' })}
                        </Typography>
                      )
                    ) : null}
                  </Grid>
                </Grid>
              )
            })
        )}
      </Box>
    </>
  )
}

export default MessageInbox
