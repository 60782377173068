import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Toolbar,
  IconButton,
  Popover,
  Grid,
  Button,
  TablePagination,
  TableSortLabel,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import WorkersNewFilter from './workers-new-filter'
import { TYPE_MAPPINGS, STRING_OPS, NUMBER_OPS, DATE_OPS, OPERATORS, MULTI_OPS, ACTIVITIES } from '../workers-constants'
import {
  WorkerData,
  FilterOptions,
  NewFilterOptions,
  StringOps,
  NumberOps,
  DateOps,
  StringFilter,
  NumberFilter,
  DateFilter,
  Order,
  CoachTaskData,
  FilterGroup,
  MultiOps,
  MultiselectFilter,
} from '../workers-types'
import { columns } from './workers-table-definitions'
import WorkersFilter from './workers-filter'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined'
import { compareAsc, format } from 'date-fns'
import ActivityCell from './activity-cell'
import { isOverdue, isToday } from '../../worker-details/worker-plan/coach-utilities'
import { allCoachActivities } from '../../worker-details/worker-plan/coach-tasks-definitions'
import Avatar from '../../shared/avatar'
import { RouteContext } from '../../../contexts/route-context'

export interface WorkersTableProps {
  workers?: Array<WorkerData>
  defaultSort?: keyof WorkerData
  globalUser?: boolean
}

const WorkersTable = (props: WorkersTableProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { defaultRoute, setDefaultParams, resetDefaultFilter, initialLoad, setInitialLoad } = useContext(RouteContext)
  const { workers, defaultSort, globalUser } = props
  const [filterAnchorElement, setFilterAnchorElement] = useState<HTMLButtonElement | null>(null)
  const [filteredWorkers, setFilteredWorkers] = useState<Array<WorkerData>>()
  const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([])
  const [newFilter, setNewFilter] = useState<NewFilterOptions>()
  const [page, setPage] = useState<number>(0)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof WorkerData>(defaultSort || 'dueDate')

  useEffect(() => {
    if (!filterAnchorElement && !initialLoad) {
      //The user is finished adding filters, update the URL accordingly.
      const newSearchFuncs = filterGroups
        .filter((group) =>
          group.filters.some(
            (filter) => filter.value || (filter.filterType.columnType === 'multi' && filter.multiValue.length),
          ),
        )
        .map((group) => {
          const conditions = group.filters
            .filter((filter) => filter.value || (filter.filterType.columnType === 'multi' && filter.multiValue.length))
            .map((filter, index) => {
              const logicalOp = index !== group.filters.length - 1 ? `~${group.logicalOperator}~` : ''
              if (filter.filterType.columnType === 'multi')
                return `${group.column}~${filter.filterType.operator}~${filter.multiValue.join(',')}${logicalOp}`
              return `${group.column}~${filter.filterType.operator}~${filter.value}${logicalOp}`
            })
            .join('')
          return conditions
        })
        .join('~plus~')
      const search = newSearchFuncs.length > 0 ? `?filter=${newSearchFuncs}` : ''
      if (search !== decodeURIComponent(location.search)) {
        setDefaultParams(newSearchFuncs.length > 0 ? newSearchFuncs : '')
        navigate(
          { pathname: defaultRoute, search: search },
          { state: { prevPath: location.pathname, prevSearch: location.search } },
        )
      }
    }
  }, [filterAnchorElement, filterGroups, initialLoad])

  useEffect(() => {
    const search = location.search

    if (search) {
      const searchParams = decodeURIComponent(search.replace('?', '').split('=')[1]).split('~plus~')
      const newFilterGroups: FilterGroup[] = []
      searchParams.forEach((paramSet) => {
        //And operations are implied via & delimiter in the URL.  Currently, a column can only be OR'd with itself.
        //If the need for more complex logical operations is needed, then this will have to change as well.
        const newFilterGroup: FilterGroup = {
          column: '',
          filters: [],
        }
        const logOpRegex = /(~or~)/gi
        const logOpMatch = paramSet.match(logOpRegex)
        const logicalSets = logOpMatch && logOpMatch[0] ? paramSet.split(logOpMatch[0]) : [paramSet]
        newFilterGroup.logicalOperator = logicalSets.length > 1 ? 'or' : undefined
        logicalSets.forEach((logSet) => {
          const regex =
            /(~ioa~)|(~iob~)|(~ib~)|(~ia~)|(~io~)|(~eq~)|(~contains~)|(~lte~)|(~gte~)|(~lt~)|(~gt~)|(~in~)/gi
          const match = logSet.match(regex)
          if (!match || match.length === 0) return

          const params = logSet.split(match[0])

          if (!newFilterGroup.column) {
            const columnName = params[0].toLowerCase()
            switch (columnName) {
              case 'duedate':
                newFilterGroup.column = 'dueDate'
                break
              case 'coachtask':
                newFilterGroup.column = 'coachTask'
                break
              case 'risklevel':
                newFilterGroup.column = 'riskLevel'
                break
              case 'taskstatus':
                newFilterGroup.column = 'taskStatus'
                break
              default:
                newFilterGroup.column = columnName
            }
          }
          const columnType = TYPE_MAPPINGS[params[0].toLowerCase()]
          const operatorType = OPERATORS[columnType]
          if (!columnType || !operatorType) return

          const parsedOp = match[0].replaceAll('~', '')

          switch (columnType) {
            case 'multi': {
              const op = MULTI_OPS.filter((opType) => opType.value.toLowerCase() === parsedOp.toLowerCase())?.[0]?.value
              if (op)
                newFilterGroup.filters.push({
                  filterType: {
                    columnType: columnType,
                    operator: op as MultiOps,
                  },
                  multiValue: params[1].split(','),
                } as FilterOptions)
              break
            }
            case 'string': {
              const op = STRING_OPS.filter((opType) => opType.value.toLowerCase() === parsedOp.toLowerCase())?.[0]
                ?.value
              if (op)
                newFilterGroup.filters.push({
                  filterType: {
                    columnType: columnType,
                    operator: op as StringOps,
                  },
                  value: params[1],
                } as FilterOptions)
              break
            }
            case 'number': {
              const op = NUMBER_OPS.filter((opType) => opType.value.toLowerCase() === parsedOp.toLowerCase())?.[0]
                ?.value
              if (op)
                newFilterGroup.filters.push({
                  filterType: {
                    columnType: columnType,
                    operator: op as NumberOps,
                  },
                  value: params[1],
                  multiValue: [],
                })
              break
            }
            case 'date': {
              const op = DATE_OPS.filter((opType) => opType.value.toLowerCase() === parsedOp.toLowerCase())?.[0]?.value
              if (op)
                newFilterGroup.filters.push({
                  filterType: {
                    columnType: columnType,
                    operator: op as DateOps,
                  },
                  value: params[1],
                  multiValue: [],
                })
              break
            }
            default:
              break
          }
        })
        newFilterGroups.push(newFilterGroup)
      })
      setFilterGroups([...newFilterGroups])
    } else setFilterGroups([])

    setInitialLoad(false)
  }, [location.search])

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setFilterAnchorElement(null)
  }

  const filterValue = (
    filterOptions: FilterOptions,
    columnValue: string | number | Array<CoachTaskData> | undefined,
  ) => {
    if (columnValue === undefined || columnValue === '' || filterOptions.value === undefined) return false
    if (filterOptions.filterType.columnType === 'string') {
      const value = columnValue as string
      switch (filterOptions.filterType.operator as StringOps) {
        case 'eq':
          return value?.trim()?.toLowerCase() === filterOptions.value?.trim()?.toLowerCase()
        case 'contains':
          return value?.trim()?.toLowerCase().includes(filterOptions.value?.trim()?.toLowerCase())
        default:
          return false
      }
    } else if (filterOptions.filterType.columnType === 'number') {
      const value = columnValue as number
      switch (filterOptions.filterType.operator as NumberOps) {
        case 'eq':
          return value === Number(filterOptions.value)
        case 'gte':
          return value >= Number(filterOptions.value)
        case 'lte':
          return value <= Number(filterOptions.value)
        case 'gt':
          return value > Number(filterOptions.value)
        case 'lt':
          return value < Number(filterOptions.value)
        default:
          return false
      }
    } else if (filterOptions.filterType.columnType === 'date') {
      const value = new Date(columnValue as string)
      const chosenDate = new Date(filterOptions.value as string)
      value.setHours(0, 0, 0, 0)
      chosenDate.setHours(0, 0, 0, 0)
      switch (filterOptions.filterType.operator as DateOps) {
        case 'io':
          return compareAsc(value, chosenDate) === 0
        case 'ioa':
          return compareAsc(value, chosenDate) >= 0
        case 'iob':
          return compareAsc(value, chosenDate) <= 0
        case 'ia':
          return compareAsc(value, chosenDate) > 0
        case 'ib':
          return compareAsc(value, chosenDate) < 1
        default:
          return false
      }
    } else {
      const value = columnValue as string
      switch (filterOptions.filterType.operator as MultiOps) {
        case 'in':
          return filterOptions.multiValue?.includes(value)
        default:
          return false
      }
    }
  }

  const filterMultiValue = (
    column: string,
    filterOptions: FilterOptions,
    newGroupFilterResult: WorkerData[],
    worker: WorkerData,
  ) => {
    switch (column.toLowerCase()) {
      case 'taskstatus': {
        const coachTaskFilters = filterGroups.find(
          (filterGroup) => filterGroup.column.toLowerCase() === 'coachtask',
        )?.filters
        if (coachTaskFilters) {
          //There should never be more than one coachTask filter, it's a multiselect so multiple values in a single array and there's only one logical operator
          const coachTaskFilter = coachTaskFilters[0]
          const filteredWorkerTasks = worker.coach_tasks.filter((task) => {
            return coachTaskFilter.multiValue.some((value) => {
              const potentialValues = ACTIVITIES.filter(
                (activity) => (activity.shortLabel as string).trim().toLowerCase() === value.trim().toLowerCase(),
              )
              return potentialValues.some(
                (val) => val.label.trim().toLowerCase() === task.activity.trim().toLowerCase(),
              )
            })
          })
          return (
            worker.coach_tasks &&
            filteredWorkerTasks.some((task) =>
              filterOptions.multiValue.some((value) => value.trim().toLowerCase() === task.status.trim().toLowerCase()),
            ) &&
            !newGroupFilterResult.some((groupFilterWorker) => groupFilterWorker.workerId === worker.workerId)
          )
        }
        return (
          worker.coach_tasks &&
          worker.coach_tasks.some((task) =>
            filterOptions.multiValue.some((value) => value.trim().toLowerCase() === task.status.trim().toLowerCase()),
          ) &&
          !newGroupFilterResult.some((groupFilterWorker) => groupFilterWorker.workerId === worker.workerId)
        )
      }
      case 'risklevel':
        return (
          worker.riskLevel &&
          filterOptions.multiValue.some(
            (multiValue) => multiValue.trim().toLowerCase() === worker.riskLevel?.trim()?.toLowerCase(),
          )
        )
      case 'coachtask':
        return (
          worker.coach_tasks &&
          filterOptions.multiValue.some((value) => {
            const potentialValues = ACTIVITIES.filter(
              (activity) => (activity.shortLabel as string).trim().toLowerCase() === value.trim().toLowerCase(),
            )
            return worker.coach_tasks.some((task) =>
              potentialValues.some((val) => val.label.trim().toLowerCase() === task.activity.trim().toLowerCase()),
            )
          })
        )
      default:
        return false
    }
  }

  //OR operations between columns are currently not supported.
  //Valid example: task === 'Create Plan' OR task === 'MC: Review Data' AND dueDate is on Today
  //In the example above, the two task conditions would be in a single filter group while the dueDate would be in a separate filter group.
  //This means that we first filter the main worker list against the two task conditions independently, then merge the results.
  //The dueDate (since it's a subsequent filter group) will filter against the reduced worker list from the task filter above ("AND" operation).
  useEffect(() => {
    if (page !== 0) setPage(0)
    if (filterGroups && workers) {
      let newWorkersList = [...workers]
      //Each filter group in the list is treated like an AND operation
      filterGroups.forEach((group) => {
        let newGroupFilterResult: WorkerData[] = []
        //Each filter in a group is treated like an OR operation
        group.filters.forEach((filterOptions) => {
          //Get a new list of unique workers based on the current filterOption.  All of these are OR operations so they filter against the same newWorkersList.
          const newLogicalGroupResult = newWorkersList.filter((worker) => {
            if (filterOptions.filterType.columnType === 'multi') {
              return filterMultiValue(group.column, filterOptions, newGroupFilterResult, worker)
            }
            const columnValue = worker[group.column as keyof WorkerData]
            return (
              filterValue(filterOptions, columnValue) &&
              !newGroupFilterResult.some((groupFilterWorker) => groupFilterWorker.workerId === worker.workerId)
            )
          })
          newGroupFilterResult.push(...newLogicalGroupResult)
        })
        //Assign the result of the first filter group to our newWorkerList, this will force subsequent filter groups to act in an "AND" fashion.
        newWorkersList = [...newGroupFilterResult]
      })
      setFilteredWorkers([...newWorkersList])
    }
  }, [filterGroups, workers])

  const removeFilterFromGroup = (column: string, indexOfFilter: number) => {
    const filterGroupToUpdate = filterGroups.find((group) => group.column === column)
    if (!filterGroupToUpdate || filterGroupToUpdate.filters.length === 1)
      setFilterGroups([...filterGroups.filter((group) => group.column !== column)])
    else if (filterGroupToUpdate) {
      setFilterGroups([
        ...filterGroups.map((group) => {
          if (group.column === column)
            return { ...group, filters: [...group.filters.filter((_, index) => index !== indexOfFilter)] }
          return group
        }),
      ])
    }
  }

  const addFilterToGroup = (column: string, filterOptions: FilterOptions) => {
    const newFilterGroups = filterGroups ? [...filterGroups] : []
    const filterGroupToUpdate = newFilterGroups.find((group) => group.column === column)
    if (filterGroupToUpdate) {
      filterGroupToUpdate.logicalOperator = 'or'
      filterGroupToUpdate.filters.push(filterOptions)
      setFilterGroups([
        ...newFilterGroups.map((group) => {
          if (group.column === column) return filterGroupToUpdate
          return group
        }),
      ])
    } else {
      newFilterGroups.push({ column: column, filters: [filterOptions] })
      setFilterGroups([...newFilterGroups])
    }
  }

  const updateFilterGroup = (column: string, filterOption: FilterOptions, index: number) => {
    const newFilterGroups = [...filterGroups]
    const filterGroupToUpdate = newFilterGroups.find((group) => group.column === column)
    if (filterGroupToUpdate) {
      const newFilters = filterGroupToUpdate.filters.map((filter, filterIndex) => {
        if (index === filterIndex) {
          return { ...filterOption }
        }
        return filter
      })
      setFilterGroups([
        ...newFilterGroups.map((group) => {
          if (group.column === column) {
            return { ...group, filters: [...newFilters] }
          }
          return group
        }),
      ])
    }
  }

  const addNewFilter = () => {
    if (!newFilter) {
      setNewFilter({})
      setPage(0)
    }
  }

  const removeNewFilter = () => {
    setNewFilter(undefined)
  }

  const updateNewFilter = (filter: NewFilterOptions) => {
    if (filter.filterType && filter.columnName && filter.filterType.operator) {
      addFilterToGroup(filter.columnName, { filterType: filter.filterType, multiValue: [] })
      setNewFilter(undefined)
      if (page !== 0) setPage(0)
    } else {
      setNewFilter({ ...filter })
      if (page !== 0) setPage(0)
    }
  }

  const handleNewColumnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const columnName = event.target.value
    if (!columnName) return
    else {
      const filter = { ...newFilter }
      filter.columnName = columnName
      switch (TYPE_MAPPINGS[columnName.toLowerCase()]) {
        case 'string':
          filter.filterType = { columnType: 'string' } as StringFilter
          break
        case 'number':
          filter.filterType = { columnType: 'number' } as NumberFilter
          break
        case 'date':
          filter.filterType = { columnType: 'date' } as DateFilter
          break
        case 'multi':
          filter.filterType = { columnType: 'multi' } as MultiselectFilter
          break
        default:
          filter.filterType = { columnType: 'string' } as StringFilter
      }
      updateNewFilter(filter)
    }
  }

  const handleNewOpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOp = event.target.value
    if (!newOp || !newFilter || !newFilter?.filterType || !newFilter.filterType?.columnType) return
    else {
      const filter = { ...newFilter }
      switch (newFilter.filterType.columnType) {
        case 'string':
          filter.filterType = { ...filter.filterType, operator: newOp } as StringFilter
          break
        case 'number':
          filter.filterType = { ...filter.filterType, operator: newOp } as NumberFilter
          break
        case 'date':
          filter.filterType = { ...filter.filterType, operator: newOp } as DateFilter
          break
        case 'multi':
          filter.filterType = { ...filter.filterType, operator: newOp } as MultiselectFilter
          break
        default:
          filter.filterType = { ...filter.filterType, operator: newOp } as StringFilter
      }
      updateNewFilter(filter)
    }
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const compareBySortDirection = (
    a: string | number | Array<CoachTaskData>,
    b: string | number | Array<CoachTaskData>,
  ): number => {
    //a (and by extension b) are strings, orderBy is the same key for both.
    if (orderBy === 'dueDate') {
      if (!isNaN(Date.parse(b as string)) && isNaN(Date.parse(a as string))) return -1
      if (isNaN(Date.parse(b as string)) && !isNaN(Date.parse(a as string))) return 1

      if (isToday(new Date(a as string))) return 1
      if (isToday(new Date(b as string))) return -1

      if (Date.parse(b as string) === Date.parse(a as string)) return 0
      return Date.parse(a as string) < Date.parse(b as string) ? 1 : -1
    } else if (orderBy === 'coachTask') {
      const shortLabelA = allCoachActivities.find((item) => item.id === a)?.shortLabel ?? ''
      const shortLabelB = allCoachActivities.find((item) => item.id === b)?.shortLabel ?? ''
      if (shortLabelB < shortLabelA) return -1
      if (shortLabelB > shortLabelA) return 1
      return 0
    } else if (isNaN(+a)) {
      if (b === '' && a !== '') return -1
      if (b !== '' && a === '') return 1
      return b.toLocaleString().localeCompare(a.toLocaleString())
    } else {
      if (b < a) return -1
      if (b > a) return 1
      return 0
    }
  }

  const handleRequestSort = (property: keyof WorkerData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  if (!filteredWorkers)
    return (
      <Box>
        <Typography>No worker data found</Typography>
      </Box>
    )

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: '10px' }}>
      <Box pl={2} pt={2}>
        <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>Workers</Typography>
      </Box>
      <Toolbar variant="dense" sx={{ width: '1393px' }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleFilterClick}
          data-testid="filter-icon"
        >
          <FilterListIcon />
        </IconButton>
        {filterGroups.length > 0 ? (
          <>
            <Typography fontSize="14px" mr={1} whiteSpace="nowrap">
              Filtered to:
            </Typography>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <>
                {filterGroups.flatMap((group, groupIndex) => {
                  return Object.entries(group.filters)
                    .filter(
                      ([_, filterOption]) =>
                        (filterOption.value !== undefined && filterOption.value.trim().length > 0) ||
                        (filterOption.filterType.columnType === 'multi' && filterOption.multiValue.length),
                    )
                    .map(([__, filterOptions], index) => {
                      const columnName =
                        group.column.toLowerCase() !== 'taskstatus'
                          ? columns.find((col) => col.id === group.column)?.label
                          : 'Status'
                      const filterType = OPERATORS[filterOptions.filterType.columnType].find(
                        (op) => op.value === filterOptions.filterType.operator,
                      )?.title

                      const lastFilter =
                        index ===
                        Object.entries(group.filters).filter(
                          ([_, filterOption]) =>
                            (filterOption.value !== undefined && filterOption.value.trim().length > 0) ||
                            (filterOption.filterType.columnType === 'multi' && filterOption.multiValue.length),
                        ).length -
                          1
                      const lastGroup = groupIndex === filterGroups.length - 1
                      return (
                        <>
                          <Box mr={0.5} whiteSpace="nowrap">
                            {columnName}
                          </Box>
                          <Box mr={0.5} whiteSpace="nowrap">
                            {filterType}
                          </Box>
                          <Box fontWeight={500} whiteSpace="nowrap">
                            {filterOptions.filterType.columnType === 'multi'
                              ? filterOptions.multiValue.join(', ')
                              : filterOptions.value}
                          </Box>
                          {!lastFilter ? (
                            <Box fontWeight={500} mr={0.5} whiteSpace="nowrap">
                              {group.logicalOperator}
                            </Box>
                          ) : null}
                          {lastFilter && !lastGroup ? (
                            <Box mx={1} whiteSpace="nowrap">
                              |
                            </Box>
                          ) : null}
                        </>
                      )
                    })
                })}
              </>
              <Box ml={3} fontSize="14px">
                <Button
                  onClick={() => {
                    const initialParams = resetDefaultFilter()
                    navigate(
                      { pathname: defaultRoute, search: initialParams ? `?filter=${initialParams}` : '' },
                      { state: { prevPath: location.pathname, prevSearch: location.search } },
                    )
                  }}
                  sx={{
                    textTransform: 'none',
                    '&:hover': {
                      background: '#ffffff',
                    },
                  }}
                >
                  Show default view
                </Button>
              </Box>
            </Box>
          </>
        ) : globalUser ? (
          <>
            <Typography fontSize="14px" mr={1} whiteSpace="nowrap">
              Currently showing:
            </Typography>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Box fontWeight={500} mr={0.5} whiteSpace="nowrap">
                All companies
              </Box>
            </Box>
          </>
        ) : null}
        {filterGroups.length === 0 ? (
          <Box ml={3} fontSize="14px">
            <Button
              onClick={() => {
                const initialParams = resetDefaultFilter()
                navigate(
                  { pathname: defaultRoute, search: initialParams ? `?filter=${initialParams}` : '' },
                  { state: { prevPath: location.pathname, prevSearch: location.search } },
                )
              }}
              sx={{
                textTransform: 'none',
                '&:hover': {
                  background: '#ffffff',
                },
              }}
            >
              Show default view
            </Button>
          </Box>
        ) : null}
      </Toolbar>
      <Popover
        open={!!filterAnchorElement}
        anchorEl={filterAnchorElement}
        sx={{ zIndex: '100' }}
        onClose={handleClose}
        data-testid="filter-popover"
      >
        <Box sx={{ p: 2, width: '715px', minHeight: '100px' }}>
          <Grid container rowSpacing={3}>
            {filterGroups.length > 0
              ? filterGroups.flatMap((group, groupIndex) => {
                  return Object.entries(group.filters).map(([_, filterOptions], filterIndex) => {
                    let logicalOperator = ''
                    if (groupIndex !== 0 && filterIndex === 0) {
                      logicalOperator = 'And'
                    } else if (filterIndex !== 0) {
                      logicalOperator = 'Or'
                    }
                    return (
                      <WorkersFilter
                        logicalOperator={logicalOperator}
                        index={filterIndex}
                        column={group.column}
                        columns={columns}
                        filterOptions={filterOptions}
                        removeFilter={removeFilterFromGroup}
                        updateFilter={updateFilterGroup}
                      />
                    )
                  })
                })
              : null}
            <WorkersNewFilter
              newFilter={newFilter}
              removeNewFilter={removeNewFilter}
              handleNewColumnChange={handleNewColumnChange}
              handleNewOpChange={handleNewOpChange}
              hideLogicalOps={filterGroups.length === 0}
              columns={columns}
            />
            <Grid item>
              <Button onClick={addNewFilter} variant="text" disabled={!!newFilter} startIcon={<AddIcon />}>
                Add filter
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '12px',
                    }}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredWorkers
              .sort((a: WorkerData, b: WorkerData) => {
                return order === 'desc'
                  ? compareBySortDirection(a[orderBy] ?? '', b[orderBy] ?? '')
                  : -compareBySortDirection(a[orderBy] ?? '', b[orderBy] ?? '')
              })
              .slice(page * 10, (page + 1) * 10)
              .map((worker, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={`${worker.workerId}-${index}`}>
                    {columns.map((column) => {
                      const value = worker[column.id]
                      if (column.id === 'name')
                        return (
                          <TableCell key={column.id}>
                            <Link to={`/worker/${worker.workerId}`}>{value}</Link>
                          </TableCell>
                        )
                      else if (column.id === 'dueDate')
                        if (value && isOverdue(new Date(value))) {
                          return (
                            <TableCell key={column.id}>
                              <Typography
                                sx={{
                                  color: '#D32F2F',
                                  fontSize: '14px',
                                }}
                              >
                                {format(new Date(value), 'M/dd/yy')}
                              </Typography>
                              <Typography
                                sx={{
                                  pl: '2px',
                                  color: '#D32F2F',
                                  fontSize: '10px',
                                }}
                              >
                                Overdue!
                              </Typography>
                            </TableCell>
                          )
                        } else if (value && isToday(new Date(value))) {
                          return <TableCell key={column.id}>TODAY</TableCell>
                        } else {
                          return (
                            <TableCell key={column.id}>{value ? format(new Date(value), 'M/dd/yy') : value}</TableCell>
                          )
                        }
                      else if (column.id === 'coachTask')
                        return (
                          <TableCell key={column.id} sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                            {value ? <ActivityCell type={value.toString()} count={worker.taskCountOnDueDate} /> : null}
                          </TableCell>
                        )
                      else if (column.id === 'coach')
                        return (
                          <TableCell key={column.id}>
                            {value ? (
                              <IconButton onClick={() => {}} sx={{ p: 0 }}>
                                <Avatar altText={value?.toString()} displayText={value?.toString()} role="Coach" />
                              </IconButton>
                            ) : null}
                          </TableCell>
                        )
                      else if (column.id === 'tier')
                        return <TableCell key={column.id}>{!value ? null : value}</TableCell>
                      else if (column.id === 'trend')
                        if (value === null) return <TableCell key={column.id}>{column.defaultValue}</TableCell>
                        else if (Number(value) > 0) {
                          return (
                            <TableCell key={column.id}>
                              <Box display="flex" data-testid="triangle-icon-up">
                                <ArrowDropUpIcon sx={{ color: '#D32F2F', transform: 'scale(1.5)', pb: '2px' }} />
                                {Math.abs(Math.round(Number(value)))}%
                              </Box>
                            </TableCell>
                          )
                        } else if (Number(value) < 0) {
                          return (
                            <TableCell key={column.id}>
                              <Box display="flex" data-testid="triangle-icon-down">
                                <ArrowDropDownIcon sx={{ color: '#4CAF50', transform: 'scale(1.5)', pb: '2px' }} />
                                {Math.abs(Math.round(Number(value)))}%
                              </Box>
                            </TableCell>
                          )
                        } else {
                          return (
                            <TableCell key={column.id}>
                              <Box display="flex" data-testid="triangle-icon-down">
                                <ArrowRightOutlinedIcon sx={{ color: '#000000', transform: 'scale(1.5)', pb: '2px' }} />
                                {Math.abs(Math.round(Number(value) * 100))}%
                              </Box>
                            </TableCell>
                          )
                        }
                      return <TableCell key={column.id}>{value ?? column.defaultValue}</TableCell>
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent={'flex-end'} alignItems="center">
        <Typography fontSize="14px">Rows per page: 10</Typography>
        <TablePagination
          component={'div'}
          rowsPerPageOptions={[10]}
          count={filteredWorkers.length}
          rowsPerPage={10}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </Paper>
  )
}

export default WorkersTable
