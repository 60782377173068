import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import React, { useEffect, useMemo, useState } from 'react'
import { SelectOption } from '../workers/workers-types'

type CallbackFunction = (value: string) => void

export interface DropdownEditPanelData {
  label: string
  selectLabel?: string
  value?: string
  options: Array<SelectOption> | undefined
  noValuePlaceholder?: string
  onSave?: CallbackFunction
}

const DropdownEditPanel = (props: DropdownEditPanelData) => {
  const { label, selectLabel, value, options, noValuePlaceholder } = props
  const [editing, setEditing] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value)

  const onEdit = () => {
    if (!editing) setEditing(true)
  }

  const onCancel = () => {
    if (editing) setEditing(false)
    if (value !== selectedValue) setSelectedValue(value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (props.onSave) {
      if (typeof selectedValue === 'undefined') props.onSave('')
      else props.onSave(selectedValue)
    }
    setEditing(false)
  }

  const saveDisabled = useMemo(() => {
    return typeof selectedValue === 'undefined'
  }, [selectedValue])

  const setOption = (option: string) => {
    setSelectedValue(option)
  }

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={0}>
          <Grid item sx={{ margin: '8px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{label}</Typography>
          </Grid>
          <Grid item sx={{ margin: '8px' }}>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {editing ? (
          <Grid container component={'form'} autoComplete="off" onSubmit={handleSubmit} ml={1}>
            <Grid item xs={11}>
              <FormControl fullWidth required>
                <InputLabel size="small" htmlFor={selectLabel}>
                  {selectLabel}
                </InputLabel>
                <Select
                  name={selectLabel}
                  size="small"
                  defaultValue=""
                  input={<OutlinedInput required label={selectLabel} />}
                  value={selectedValue}
                  onChange={(event: SelectChangeEvent) => setOption(event.target.value)}
                >
                  {options?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={11}>
              <Box justifyContent={'flex-end'} display={'flex'}>
                <Button variant="text" onClick={() => onCancel()}>
                  Cancel
                </Button>
                <Button variant="text" type="submit" name="update" disabled={saveDisabled}>
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : value ? (
          <Typography sx={{ fontSize: '14px', fontWeight: 500, margin: '8px' }}>
            {options?.find((item) => item.id === value)?.label}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '14px', fontWeight: 300, color: '#7F7F7F', margin: '8px' }}>
            {noValuePlaceholder}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default DropdownEditPanel
