import { Divider, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CoachDetailData, CompanyDetailData, SelectOption } from '../workers/workers-types'
import DropdownEditPanel from './dropdown-edit-panel'

export interface CompanyContactsData {
  company?: CompanyDetailData
}

const CompanyContacts = (props: CompanyContactsData) => {
  const { company } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [coaches, setCoaches] = useState<Array<SelectOption>>()
  const [coachId, setCoachId] = useState(company?.coachId)

  const getCoachListData = (abortController: AbortController) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/users/coaches`, {
      method: 'GET',
      signal: abortController.signal,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the /worker endpoint.')
      })
      .then((data: Array<CoachDetailData>) => {
        const newData = data.map((coachData) => {
          return {
            id: coachData.coachId,
            label: coachData.name ?? '',
          }
        })
        setCoaches(newData as SelectOption[])
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    const abortController = new AbortController()
    getCoachListData(abortController)

    return () => {
      abortController.abort()
    }
  }, [])

  const addCoachToCompany = (newCoachId: string) => {
    event?.preventDefault()
    setLoading(true)
    const payload = {
      companyId: company?.companyId!,
      coachId: newCoachId,
    }
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/company/addCoach`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the PATCH /coach/task endpoint.')
      })
      .then((responseData) => {
        setCoachId(responseData.coachId)
        setLoading(false)
      })
      .catch((error) => {
        //TODO: Figure out what to do with errors
        setLoading(false)
      })
  }

  return (
    <Grid container direction="column" sx={{ marginTop: '8px' }} spacing={0}>
      <Grid item sx={{ margin: '8px' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>Contacts</Typography>
      </Grid>
      <Grid item sx={{ marginTop: '4px' }}>
        <Divider />
      </Grid>
      <Grid item sx={{ marginTop: '4px' }}>
        <DropdownEditPanel
          label="Bardavon Coach"
          selectLabel="Coach"
          value={coachId}
          options={coaches}
          noValuePlaceholder="No coach assigned"
          onSave={addCoachToCompany}
        />
      </Grid>
      <Grid item sx={{ marginTop: '4px' }}>
        <Divider />
      </Grid>
      <Grid item sx={{ marginTop: '4px' }}>
        <DropdownEditPanel label="Safety Officer" options={[]} noValuePlaceholder="Not implemented" />
      </Grid>
    </Grid>
  )
}

export default CompanyContacts
