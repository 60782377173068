import { Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { CompanyDetailData } from '../workers/workers-types'
import DropdownEditPanel from './dropdown-edit-panel'

export interface CompanyTierData {
  company?: CompanyDetailData
}

const CompanyTier = (props: CompanyTierData) => {
  const { company } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [tierId, setTierId] = useState(company?.companyTier)


  const addTierToCompany = (newTierId: string) => {
    event?.preventDefault()
    setLoading(true)

    const updatedValues = {
      companyId: company?.companyId,
      companyTier: Number(newTierId),
    }
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/company/tier`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...updatedValues }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the PATCH /company/tier endpoint.')
      })
      .then((responseData) => {
        setTierId(responseData.companyTier)
        setLoading(false)
      })
      .catch((error) => {
        //TODO: Figure out what to do with errors
        setLoading(false)
      })
  }

  const tierArray = [
    { id: '3', label: 'Tier 3' },
    { id: '2', label: 'Tier 2' },
    { id: '1', label: 'Tier 1' },
  ]

  return (
    <Grid container direction="column" sx={{ marginTop: '8px' }} spacing={0}>
      <Grid item sx={{ marginTop: '4px' }}>
        <DropdownEditPanel
          label="Tier"
          selectLabel="Tier"
          value={tierId?.toString()}
          options={tierArray}
          noValuePlaceholder="No tier assigned"
          onSave={addTierToCompany}
        />
      </Grid>
      <Grid item sx={{ marginTop: '4px' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 300, color: '#7F7F7F', margin: '8px' }}>
          Not implemented
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CompanyTier
