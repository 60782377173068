import {
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  TextField,
  Box,
  Button,
  Grid,
  SelectChangeEvent,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { coachActivities, coachStatuses } from './coach-tasks-definitions'
import { DatePicker } from '@mui/x-date-pickers'
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import { AuthenticationContext } from '../../../contexts/authentication-context'
import { Activity, CoachTaskStatus, NewCoachTaskResponse, Status, CoachTaskActivity } from '../worker-details-types'
import { isOverdue } from './coach-utilities'
import { ProgramData } from '../../workers/workers-types'

interface CoachNewTaskProps {
  workerTier?: number
  workerId: string
  toggleShowNewCoach: (showNewCoachForm: boolean) => void
  setLoading: (loading: boolean) => void
  addCoachTask: (newTask: NewCoachTaskResponse) => void
}
const CoachNewTask = (props: CoachNewTaskProps) => {
  const { addCoachTask, toggleShowNewCoach, setLoading, workerId, workerTier } = props
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [note, setNote] = useState<string>('')
  const [taskActivity, setTaskActivity] = useState<string>('')
  const [taskStatus, setTaskStatus] = useState<string>('')
  const [programs, setPrograms] = useState<Array<ProgramData>>([])
  const [selectedProgram, setSelectedProgram] = useState<string>('')

  const { userInfo } = useContext(AuthenticationContext)

  const handleDateChange = (date: Date | null) => {
    if (date !== null) {
      const newDueDate = date
      newDueDate.setHours(0, 0, 0, 0)
      setDueDate(newDueDate)
    } else if (date === null && dueDate !== null) {
      setDueDate(null)
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    getPrograms(abortController)

    return () => {
      abortController.abort()
    }
  }, [])

  const getPrograms = (abortController: AbortController) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/cmp_peerwell/programs`, {
      method: 'GET',
      signal: abortController.signal,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the /worker endpoint.')
      })
      .then((data: Array<ProgramData>) => {
        setPrograms(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const handleSubmit = () => {
    event?.preventDefault()
    setLoading(true)
    const name = `${userInfo?.firstname} ${userInfo?.lastname ?? ''}`
    const payload = {
      workerId: workerId,
      activity: coachActivities.find((activity) => taskActivity === activity.id)?.label,
      dueDate: dueDate?.toISOString(),
      programId: selectedProgram.length === 0? null: selectedProgram,
      status: coachStatuses.find((status) => taskStatus === status.id)?.label,
      note: note.length > 0 ? `${note} (${name.trim()})` : null,
    }
    fetch(`${process.env.REACT_APP_CARE_PORTAL_API}/coach/task`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw Error('An error occurred when calling the PATCH /coach/task endpoint.')
      })
      .then((responseData) => {
        addCoachTask({
          taskId: responseData.taskId,
          dueDate: payload.dueDate as string,
          activity: payload.activity as Activity,
          status: payload.status as Status,
          notes: responseData.noteId
            ? [
                {
                  noteId: responseData.noteId,
                  body: payload.note as string,
                  status: payload.status as Status,
                  createdDate: new Date().toISOString(),
                  programId: payload.programId ?? '',
                },
              ]
            : [],
        })
        toggleShowNewCoach(false)
        setLoading(false)
      })
      .catch((error) => {
        //TODO: Figure out what to do with errors
        setLoading(false)
      })
  }

  const saveDisabled = useMemo(() => {
    if (!dueDate || (dueDate && isOverdue(dueDate))) return true
    return (taskActivity.length === 0 || taskStatus.length === 0) 
  }, [taskActivity, taskStatus, dueDate])

  const handleOnChange = (value: string) => {
    setTaskActivity(value)
    setSelectedProgram('')
  }

  return (
    <>
      <Grid item container spacing={1} component={'form'} autoComplete="off" onSubmit={handleSubmit} mb={3}>
        <Grid item xs={5}>
          <FormControl fullWidth required>
            <InputLabel size="small">Coach task</InputLabel>
            <Select
              name="activity"
              size="small"
              defaultValue=""
              input={<OutlinedInput label="Coach task" />}
              value={taskActivity}
              onChange={(event: SelectChangeEvent) => setTaskActivity(event.target.value)}
            >
              {coachActivities.map((activity: CoachTaskActivity) => (
                <MenuItem
                  key={activity.id}
                  value={activity.id}
                  disabled={
                    workerTier === 1 &&
                    (activity.id === 'peerwell_assign_program' || activity.id === 'peerwell_follow_up')
                  }
                >
                  {activity.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <DatePicker
              label="Due Date*"
              value={dueDate}
              onChange={handleDateChange}
              inputFormat="MM/dd/yy"
              mask="__/__/__"
              disablePast
              renderInput={(params) => <TextField name="dueDate" helperText="MM/DD/YY" size="small" {...params} />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth required>
            <InputLabel size="small" htmlFor="status">
              Status
            </InputLabel>
            <Select
              name="status"
              size="small"
              defaultValue=""
              input={<OutlinedInput required label="Status" />}
              value={taskStatus}
              onChange={(event: SelectChangeEvent) => setTaskStatus(event.target.value)}
            >
              {coachStatuses.map((status) => (
                <MenuItem
                  key={status.id}
                  value={status.id}
                  sx={{ display: status.id === 'overdue' ? 'none' : 'inherit' }}
                >
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="expand row" size="small" onClick={() => toggleShowNewCoach(false)}>
            <KeyboardArrowUpIcon />
          </IconButton>
        </Grid>
        { taskActivity === 'peerwell_assign_program' ?
          <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel size="small">Program</InputLabel>
            <Select
              name="program"
              size="small"
              defaultValue=""
              input={<OutlinedInput label="Program" />}
              value={selectedProgram}
              onChange={(event: SelectChangeEvent) => setSelectedProgram(event.target.value)}
            >
              {programs.map((program) => (
                <MenuItem
                  key={program.programId}
                  value={program.programId}
                >
                  {program.programName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        : null}
        <Grid item xs={11}>
          <TextField
            size="small"
            multiline
            fullWidth
            variant="outlined"
            inputProps={{
              maxLength: 500,
            }}
            label="Notes"
            name="note"
            value={note}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setNote(event.target.value)}
            helperText={`${note?.length ?? 0}/500`}
            sx={{
              '& .MuiFormHelperText-root': {
                position: 'absolute',
                top: '-12px',
                right: '-5px',
                background: 'white',
                zIndex: 1,
              },
            }}
          ></TextField>
          <Box justifyContent={'flex-end'} display={'flex'}>
            <Button variant="text" onClick={() => toggleShowNewCoach(false)}>
              Cancel
            </Button>
            <Button variant="text" type="submit" name="update" disabled={saveDisabled}>
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
export default CoachNewTask
