import { useContext } from 'react'
import MemoizedApp from './app'
import { AuthenticationContext } from './contexts/authentication-context'
import { LoadingContext } from './contexts/loading-context'
import { RouteContext } from './contexts/route-context'

const AppWrapper = () => {
  const { isAuthenticated } = useContext(AuthenticationContext)
  const { loading } = useContext(LoadingContext)
  const { defaultParams } = useContext(RouteContext)

  return <MemoizedApp isAuthenticated={isAuthenticated} loading={loading} defaultParams={defaultParams} />
}

export default AppWrapper
